
//=============================================================================
// Función genérica API Reneé
//=============================================================================

export default async function APICALL(data) {

	const isTestEnvironment = window.location.hostname !== 'app.renee.cl';

	// return fetch('http://localhost:8070/MainCall', {
	return fetch('https://api.renee.cl/MainCall', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(	{	data,
															environment: isTestEnvironment ? 'test' : 'production'}),
															// environment: 'production'}),
			mode: 'cors'
	})
	.then(response => response.json())
	.catch(error => {
			console.error('Error during fetch:', error);
			return { response: '' };
	});
	
}
