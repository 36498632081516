
//=============================================================================
// Función genérica API OPENAI
//=============================================================================

export default async function AICALL(data) {
  
  // return fetch('http://localhost:8060/AIcall', {
  return fetch('https://api.renee.cl/Aicall', {
    method: 'POST',
    headers: {  'Content-Type': 'application/json'},
    body: JSON.stringify(data)
  })
  .then(response => response.json())
  .catch(error => {
    console.error('Error during fetch:', error);
    return { response: '' };
  });
}