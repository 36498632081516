import './css/App.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import APICALL from './APICALL';
import AICALL from './AICALL';

export default function Formulario() {
  const navigate = useNavigate();
  const [CVlist, setCVlist] = useState([]);
  const [selectlist, setselectlist] = useState([]);
  const [selectedCVId, setSelectedCVId] = useState(null);
  const [sortType, setSortType] = useState('score');
  const [Form, setForm] = useState([]); // Store questions as objects with title and content

  const goto07 = () => { navigate('/Candidatos') };  

  const sortCVlist = () => {
    const sortedList = [...CVlist].sort((a, b) => {
      if (sortType === 'score') {
        const notaA = a.Nota ? JSON.parse(a.Nota) : 0;
        const notaB = b.Nota ? JSON.parse(b.Nota) : 0;
        return notaB - notaA;
      } else {
        return a.name.localeCompare(b.name);
      }
    });
    return sortedList;
  };

  async function Handleselect(name) {
    setCVlist(prevCVlist =>
      prevCVlist.map(cv =>
        cv.name === name
          ? { ...cv, select: !cv.select} 
          : cv
      )
    );
  
    const updatedSelectList = selectlist.includes(name)
      ? selectlist.filter(id => id !== name)
      : [...selectlist, name];
  
    setselectlist(updatedSelectList);
    
    console.log(updatedSelectList); 
  
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo'),
        SelectList: updatedSelectList 
      });
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
  }

  async function ReneePregunta(index) {

    const instruction = 'Genera una pregunta simple sin alternativas relevante para el perfil : ' + sessionStorage.getItem('perfil') 
    
    try {
      const consulta = await AICALL({
        apicall: '04',
        data: instruction
      });

      const updatedForm = [...Form];
      updatedForm[index] = {
        title: consulta.reply,
        content:' ',
      };
      setForm(updatedForm);

    
    } catch (error) {
      console.error('Error during fetch:', error);
    }
    
  }

  async function saveForm() {
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo'),
        Form: Form
      });
    } catch (error) {
      console.error('Error during fetch:', error);
    }
  }

  const handleInputChange = (index, field, value) => {
    const updatedForm = [...Form];
    updatedForm[index][field] = value;
    setForm(updatedForm);
  };

  const AddPregunta = () => {
    setForm([...Form, { title: '', content: '' }]);
  };

  const DeletePreunta = (indexToRemove) => {
    const updatedForm = Form.filter((_, index) => index !== indexToRemove);
    setForm(updatedForm);
  };

  const moveUp = (index) => {
    if (index > 0) {
      const newForm = [...Form];
      [newForm[index], newForm[index - 1]] = [newForm[index - 1], newForm[index]];
      setForm(newForm);
    }
  };

  const moveDown = (index) => {
    if (index < Form.length - 1) {
      const newForm = [...Form];
      [newForm[index], newForm[index + 1]] = [newForm[index + 1], newForm[index]];
      setForm(newForm);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await APICALL({
        apicall: 'PD_fetch',
        code: sessionStorage.getItem("process"),
        U_emp: sessionStorage.getItem('Grupo')
      });
  
      localStorage.removeItem('fetched');
      localStorage['fetched'] = data;
  
      const PreguntaD1 = { "title": "Nombre y Apellido", "content": " " };
      const PreguntaD2 = { "title": "Dirección email", "content": " " };
      const PreguntaD3 = { "title": "Número de teléfono actualizado", "content": " " };
      const PreguntaD4 = { "title": "En caso que seas seleccionado, ¿cuál es tu disponibilidad?", "content": " " };
      const PreguntaD5 = { "title": "Coméntanos cuál es tu nivel de renta actual o tu última renta en caso que no estés trabajando", "content": " " };
      const PreguntaD6 = { "title": "Coméntanos cuál es tus expectativas de renta líquida (puede ser un rango)", "content": " " };
  
      const questionExists = (form, questionTitle) => form.some(question => question.title === questionTitle);
  
      if (data.Form) {
        let updatedForm = [...data.Form];
        if (!questionExists(updatedForm, PreguntaD1.title)) updatedForm.unshift(PreguntaD1);
        if (!questionExists(updatedForm, PreguntaD2.title)) updatedForm.unshift(PreguntaD2);
        if (!questionExists(updatedForm, PreguntaD3.title)) updatedForm.unshift(PreguntaD3);
        if (!questionExists(updatedForm, PreguntaD4.title)) updatedForm.push(PreguntaD4);
        if (!questionExists(updatedForm, PreguntaD5.title)) updatedForm.push(PreguntaD5);
        if (!questionExists(updatedForm, PreguntaD6.title)) updatedForm.push(PreguntaD6);
        setForm(updatedForm);
      } else {
        setForm([PreguntaD1, PreguntaD2, PreguntaD3, PreguntaD4, PreguntaD5, PreguntaD6]);
      }

      if (data.CVData){
        setCVlist(data.CVData);
        setselectlist(Array.isArray(data.SelectList) 
        ? data.SelectList               
        : data.SelectList               
          ? [data.SelectList]         
          : []);
      }
    };
  
    fetchData();
    // eslint-disable-next-line
  }, []);
  
  

  return (
    <div className="BackGround">
      <div className="typing-effect-container">
        <h2 className="typing-effect">
          Texto formularios poner algo aqui
        </h2>
      </div>
      <div className='MainBody'>
        <div className='boxfit' style={{ maxWidth: '20vw' }}>
          <div style={{display:'flex'}}>
            <h3 className='boxtitle'>Lista de Candidatos</h3> 
            <button className='sortbutton'
                    onClick={() => setSortType(sortType === 'score' ? 'name' : 'score')}>
              {sortType === 'score' ? 'Puntaje (desc)' : 'Nombre (A-Z)'}
            </button>
          </div>
          <div className='notas'>
          {CVlist && CVlist.length > 0 ? (
            sortCVlist()
              .filter(reply => selectlist.includes(reply.name))
              .map((reply, index) => (
              <div key={reply.name} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <button
                  className={ selectlist.includes(reply.name) ? 'green-button' : 
                                              reply.name === selectedCVId ? 'highlighted-button' : 
                                                                            'normal-button'}
                  onClick={() => {
                    setSelectedCVId(reply.name);
                  }}
                >
                  {reply.name}
                </button>
                <button className={ !reply.Nota ? 'normal-button' : 
                                                  JSON.parse(reply.Nota) < 50 ? 'red-button' : 
                                                                                JSON.parse(reply.Nota) < 70 ? 'yellow-button' : 
                                                                                                              'blue-button'}
                        style={{maxWidth:'4vw'}}>
                  {reply.Nota ? JSON.parse(reply.Nota) : ' - '}{'%'}</button>
                <input  type="checkbox" 
                        className='select-checkbox'
                        checked={selectlist ? selectlist.includes(reply.name) : false}
                        onChange={() => {
                          Handleselect(reply.name);
                        }}></input>
              </div>
            ))
          ) : (
            <button>No hay candidatos</button>
          )}
          </div>
        </div>
        &nbsp;
        <div className='boxfit'>
          <h3 className='boxtitle'>Formulario Pre-Screening</h3>
          <div className='notas' style={{overflow: 'scroll'}}>
            <div style={{marginRight: '3vh', width:'-webkit-fill-available', position:'fixed', backgroundColor:'var(--light)'}}>
              <button onClick={AddPregunta} style={{marginRight: '3vh'}}>Agregar Pregunta</button>
            </div>
            <div style={{marginTop: '5vh'}}>
              {Form.length > 0 && (
                <div style={{display: 'grid', gridTemplateColumns: '1fr'}}>
                  {Form.map((block, index) => (
                    <div key={index} className='notas' style={{ display:'flex', flexDirection:'row', marginBottom: '1vh', marginRight: '1vh', gap: '1vh'}}>
                      <div style={{flex: 1}}>
                        <div style={{display:'flex'}}>
                          {index} <textarea
                            value={block.title}
                            className='notas'
                            onChange={(e) => handleInputChange(index, 'title', e.target.value)}
                            placeholder="Editar título"
                            rows="1"
                            style={{height: '3vh', marginBottom: '1vh' , marginLeft:'1vh'}}
                          />
                        </div>
                        <textarea
                          value={block.content}
                          className='notas'
                          onChange={(e) => handleInputChange(index, 'content', e.target.value)}
                          placeholder="Editar contenido"
                          rows="5"
                          style={{height: '10vh', marginBottom: '1vh'}}
                        />
                      </div>
                      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                        <button onClick={() => moveUp(index)} style={{marginBottom: '0.5vh'}}>▲</button>
                        <button onClick={() => moveDown(index)} style={{marginBottom: '0.5vh'}}>▼</button>
                        <button onClick={() => ReneePregunta(index)} style={{marginBottom: '0.5vh'}}>Consulta Renee</button>
                        <button onClick={() => DeletePreunta(index)} style={{marginBottom: '0.5vh'}}>Eliminar</button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='bottom'>
        <button onClick={goto07}>volver</button>
        <button onClick={saveForm}>Guardar Formulario</button>
      </div>
    </div>
  );
}
