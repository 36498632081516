
import './functions/css/index.css';
import './functions/css/App.css';

import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import Header from './components/header';
import HeaderAdmin from './components/headerAdmin.js';
import App from './App';

import Nuser from './functions/00_NuevoUsuario.js';
import NEuser from './functions/00_NuevoUsuarioEmp.js';

import ErrorBoundary from './functions/XX_Errorboundry.js';

import Pprocess   from './functions/01_printprocess.js';
import Nopciones  from './functions/02_newoptions.js';
import Editor     from './functions/03_Text_editor.js';
import Eprocess   from './functions/04_edtprocess.js';
import Perfil     from './functions/05_GenPerfil.js';
import Public     from './functions/06_Public.js';
import Recuperar  from './functions/07_Recover.js';
import Formulario from './functions/08_Formulary.js';

import MS         from './functions/00_MS.js';

const root = ReactDOM.createRoot(document.getElementById('root'));



if (!sessionStorage.getItem('token')){
  root.render(
    <React.StrictMode>
      <ErrorBoundary>
        <Router>
          <Routes>
              <Route path="/"                   element={<App/>} />  
              <Route path="/MS"                 element={<MS/>} /> 
          </Routes>
        </Router>
      </ErrorBoundary>
    </React.StrictMode>
  )
}
else{
  if (sessionStorage.getItem('rol') === "Admin"){
    root.render(
      <React.StrictMode>
        <ErrorBoundary>
          <Router>
            <HeaderAdmin/>
            <Routes>

                <Route path="/"                   element={<Pprocess/>} />  
                <Route path="/MS"                 element={<MS/>} /> 
                <Route path="/NuevoUser"          element={<Nuser/>} /> 
                <Route path="/MisProcesos"        element={<Pprocess/>} />
                <Route path="/CProceso"           element={<Nopciones/>} />
                <Route path="/EProceso"           element={<Eprocess/>} />
                <Route path="/Notas"              element={<Editor/>} />
                <Route path="/GenPerfil"          element={<Perfil/>} />
                <Route path="/GenOferta"          element={<Public/>} />
                <Route path="/Candidatos"         element={<Recuperar/>} />
                <Route path="/Formulario"         element={<Formulario/>} />
          
            </Routes>
          </Router>
        </ErrorBoundary>
      </React.StrictMode>
    );
  }
  else{
    if (sessionStorage.getItem('rol') !== "User"){
      root.render(
        <React.StrictMode>
          <ErrorBoundary>
            <Router>
              <HeaderAdmin/>
              <Routes>

                  <Route path="/"                   element={<Pprocess/>} />  
                  <Route path="/MS"                 element={<MS/>} /> 
                  <Route path="/NuevoUser"          element={<NEuser/>} /> 
                  <Route path="/MisProcesos"        element={<Pprocess/>} />
                  <Route path="/CProceso"           element={<Nopciones/>} />
                  <Route path="/EProceso"           element={<Eprocess/>} />
                  <Route path="/Notas"              element={<Editor/>} />
                  <Route path="/GenPerfil"          element={<Perfil/>} />
                  <Route path="/GenOferta"          element={<Public/>} />
                  <Route path="/Candidatos"         element={<Recuperar/>} />
                  <Route path="/Formulario"         element={<Formulario/>} />

              </Routes>
            </Router>
          </ErrorBoundary>
        </React.StrictMode>
      );
    }
    else{
      root.render(
        <React.StrictMode>
          <ErrorBoundary>
            <Router>
              <Header/>
              <Routes>

                  <Route path="/"                   element={<Pprocess/>} />  
                  <Route path="/MS"                 element={<MS/>} /> 
                  <Route path="/MisProcesos"        element={<Pprocess/>} />
                  <Route path="/CProceso"           element={<Nopciones/>} />
                  <Route path="/EProceso"           element={<Eprocess/>} />
                  <Route path="/Notas"              element={<Editor/>} />
                  <Route path="/GenPerfil"          element={<Perfil/>} />
                  <Route path="/GenOferta"          element={<Public/>} />
                  <Route path="/Candidatos"         element={<Recuperar/>} />
                  <Route path="/Formulario"         element={<Formulario/>} />
                  
              </Routes>
            </Router>
          </ErrorBoundary>
        </React.StrictMode>
      );
    }
  }
}

reportWebVitals();
