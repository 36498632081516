import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error caught by ErrorBoundary:', error, errorInfo);
    this.setState({
      error: error.toString(), 
      errorInfo: errorInfo.toString(), 
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h1>Oops, parece que encontramos un error.</h1>
          <h2>Error: {this.state.error}</h2>
          <details
            style={{
              marginTop: '1vh',
              whiteSpace: 'pre-wrap',
              flex: '1',
              overflowY: 'scroll',
              textAlign: 'left',
              color: 'red'
            }}
          >
            <summary>Detalles del error</summary>
            <h3>{this.state.errorInfo}</h3>{this.state.errorInfo}
          </details>
        </div>
      );
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;
