
//=============================================================================
// Pantalla Notas del proceso
//=============================================================================

import './css/App.css';
import React, { useState, useEffect, useRef  } from 'react';
import { useNavigate } from 'react-router-dom';
import APICALL from './APICALL';

export default function Editor() {

  const navigate = useNavigate();
  const [text, setText]                   = useState('');
  const [fetchedData, setFetchedData]     = useState(''); 
  const [memos, setmemos]                 = useState([]); 
  const [inputKey, setInputKey]           = useState(Date.now());
  const [hiddenButtons, setHiddenButtons] = useState([]);
  const fileInputRef                      = useRef(null);
  const processname                       = sessionStorage.getItem("process");


  const handleChange = (event) => { 
    setText(event.target.value); 
  };

  const handleButtonClick = (item) => {
    setHiddenButtons([...hiddenButtons, item]);
  };

  const goto01 = () => {
    save();
    navigate('/MisProcesos');
  };

  const goto04 = () => {
    localStorage["notas"]   = text;
    save();
    navigate('/EProceso');
  };


  async function save() {
    if(text !== ''){
      try {
        await APICALL({
          apicall: 'PD_update',
          code: processname, 
          U_emp: sessionStorage.getItem('Grupo'),
          notas: text , 
          respuesta: fetchedData.GPTreply
        });
      } catch (error) {
        console.error('Error during fetch:', error);
        return { token: null };
      }
    }
  };

  const handleFileChange = (event) => {

    const file = event.target.files[0];
    if (file) {
        const reader = new FileReader();
        reader.onload = function(e) {
            const content = e.target.result;
            document.getElementById('fileContent').textContent = content;
            localStorage['archivo'] = content;
        };
        reader.readAsText(file);
    }
  };

  async function getContent() {
    setInputKey(Date.now());
    localStorage['archivo'] = ''
  }

  useEffect(() => {
    localStorage['reply'] = ''
    localStorage['notas'] = ''
    const fetchData = async () => {
      setmemos(require("./docs/Preguntas.json"));
      const data = await APICALL({
        apicall :'PD_fetch' ,
        code :sessionStorage.getItem("process"),
        U_emp: sessionStorage.getItem('Grupo')
      });
      setFetchedData(data);
      setText(data.notas);
      localStorage['archivo'] = ''
      localStorage['reply'] = data.GPTreply
      localStorage['notas'] = data.notas
    };
    fetchData();
  }, []);

  const dataItems = [
    { label: 'EMPRESA',                 value: fetchedData.E_name 			}, 
    { label: 'CARGO',                   value: fetchedData.Charge_name 	}, 
    { label: 'INTERLOCUTOR',            value: fetchedData.I_name 			},
		{ label: 'PARTNER ENCARGADO',       value: fetchedData.R_name 		  }
  ];

  return (
    // main 
    <div className="BackGround">
      <div className="typing-effect-container">
        <h2 className="typing-effect" >Levantaremos perfil con tu cliente (interno o externo), registra aqui las notas que tomes de esa conversación</h2>
			</div>
      
      <div className="MainBody">
        <div className='boxfit'>
          <h3 className = 'boxtitle'>Tus notas</h3>
          <textarea className='notas'
            value={text}
            onChange={handleChange}
            placeholder="escriba aqui sus notas..."
            spellCheck 
          />
        </div>
        &nbsp; 

        <div>
          <div className="data-boxes">
            {dataItems.map((item, index) => (
              <div  key={index} 
                    className="data-box">
          
                <strong>{item.label}:</strong> {item.value}
              </div>
            ))}
            <input  key = {inputKey}
                    id = "fileContent"
                    type="file"  
                    accept=".txt" 
                    onChange={handleFileChange}
                    ref={fileInputRef} 
                    className="file-input" />
          
            <button className='file-input' 
                    onClick={getContent}>
                    Eliminar Archivo</button>
          </div>
          &nbsp; 
          <div className='memos-box'>
            <div className='memos-title'>
              <h3>Acá te dejo algunas recomendaciones para el levantamiento de perfil</h3>
            </div>
              {memos.map((item) => (
                <button
                  key={item}
                  className={`memos ${hiddenButtons.includes(item) ? 'hidden' : ''}`}
                  onClick={() => handleButtonClick(item)}
                >
                  <strong>{item} </strong> 
                </button>
              ))}
          </div>
        </div>
      </div>
        <div className='bottom'>
          <button onClick={goto01}>volver</button>
          <div></div>
          <div></div>
          <div></div>
          <button onClick={goto04}>Guardar y continuar a Detalles del Proceso</button>
        </div>
    </div>
  );
}
