import './header.css';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Chatbox from './Chatbox';

function logout() {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('chatMessages');
    window.location.replace("https://renee.cl");
};

const namestring = sessionStorage.getItem('token');

const HeaderAdmin = () => {
    const [showChatbox, setShowChatbox] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('Menu');
    const [rolmenushow, setRolmenue] = useState(false);
    const Rollist = JSON.parse(sessionStorage.getItem('gruposlista'));

    const navigate = useNavigate();
    let location = useLocation();
    location = location.pathname;

    const goto01 = () => { 
        setIsOpen(false);
        setRolmenue(false);
        navigate('/MisProcesos');
    };

    const gotoNU = () => {  
        setIsOpen(!isOpen);
        setRolmenue(false);
        navigate('/NuevoUser');
    };

    const rolmenu = () => {setRolmenue(!rolmenushow)}
    
    const rolchange = (option) => {sessionStorage.setItem('Grupo', option);}

    const toggleChatbox = () => {
        setIsOpen(!isOpen);
        setRolmenue(false);
        setShowChatbox(!showChatbox);
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
        setRolmenue(false);
    }
    const handleOptionClick = (option) => {
        if (option.action) {
            option.action(); 
        }
        setSelectedOption('Menu'); 
    };

    const options = [
      { label: '🏠 Inicio'              ,   action: goto01 },
      { label: '💬 Chat'                ,   action: toggleChatbox },
      { label: '🤵 Crear Usuario'       ,   action: gotoNU },
      { label: '🏦 Cambiar de Empresa'  ,   action: rolmenu },
      { label: '⛔ Salir de Reneé'      ,   action: logout },
    ];

    return (
        <header className="header">
            <div className="titles">
              <img 
                  src="/favicon.png" 
                  alt="favicon" 
                  className="logo" 
                  onClick={goto01}
              />
              &nbsp;  
              {location === "/MisProcesos" || location === "/NuevoUser" || location === '/CProceso'|| location === '/NProceso' || location === '/'?(
                  <h3 className="title">Bienvenido a Reneé || {namestring.toUpperCase()} {sessionStorage.getItem('Grupo').toUpperCase()}</h3>
              ):
              (
                  <h3 className="title">{sessionStorage.getItem('name').toUpperCase() || 'Error'}</h3>          
              )}
              &nbsp;  
              <div className="buttons">
                <div className="custom-dropdown">
                  <div className="dropdown-header" onClick={toggleDropdown}>
                      {selectedOption}
                  </div>
                  {isOpen && (
                      <div className="dropdown-list">
                          {options.map((option, index) => (
                              <div
                                  key={index}
                                  className={`dropdown-item ${option.action ? '' : 'dropdown-title'}`}
                                  onClick={() => handleOptionClick(option)}>
                                  {option.label}
                              </div>
                          ))}
                      </div>
                  )}
                  { Rollist && rolmenushow ? (
                      <div className="dropdown2">
                          {Rollist.map((option, index) => (
                            <div
                                key={index}
                                className={`dropdown-item ${option ? '' : 'dropdown-title'}`}
                                onClick={() => {rolchange(option);
                                                setIsOpen(!isOpen);
                                                setRolmenue(false);
                                }}>
                                {option}
                            </div>
                            ))}

                      </div>
                  ) : (
                    <div>
                    </div>
                  )}
              </div>
              </div>
            </div>
            <div className="subtitle">
                <button className={location === "/" || location === '/MisProcesos' ? 'sub-box-on' : 'sub-box-off'}>
                    <h3>Tus Procesos</h3>
                </button>
                <button className={location === "/CProceso" ? 'sub-box-on' : 'sub-box-off'}>
                    <h3>Crear Proceso</h3>
                </button>
                <button className={location === "/Notas" ? 'sub-box-on' : 'sub-box-off'}>
                    <h3>Levantamiento de Perfil</h3>
                </button>
                <button className={location === "/EProceso" ? 'sub-box-on' : 'sub-box-off'}>
                    <h3>Datos del Perfil</h3>
                </button>
                <button className={location === "/GenPerfil" ? 'sub-box-on' : 'sub-box-off'}>
                    <h3>Perfil de Búsqueda</h3>
                </button>
                <button className={location === "/GenOferta" ? 'sub-box-on' : 'sub-box-off'}>
                    <h3>Aviso de Empleo</h3>
                </button>
                <button className={location === "/Candidatos" ? 'sub-box-on' : 'sub-box-off'}>
                    <h3>Revision de candidatos</h3>
                </button>
                <button className={location === "/Formulario" ? 'sub-box-on' : 'sub-box-off'}>
                    <h3>Formulario Pre-Screening</h3>
                </button>
            </div>
            {showChatbox && <Chatbox onClose={toggleChatbox} />}
        </header>
    );
};

export default HeaderAdmin;
