import React, { useState, useEffect } from 'react';
import './css/MS.css';
import './css/App.css';
import { useNavigate } from 'react-router-dom';

// Constants
const ROWS = 20;
const COLS = 50;
const MINES = 200;
let MineC = MINES;
let OpenC = ROWS*COLS;

export default function MS() {

  const navigate = useNavigate();

  const [board, setBoard]       = useState([]);
  const [gameOver, setGameOver] = useState(false);
  const [face, setFace]         = useState('😄');
  

  function wincheck(){
    if (OpenC === 0 && MineC >= 0){
      alert('GANASTE !!!')
      setGameOver(true)
      setBoard(revealAllMines(board));
      setFace('😎');
      return;
    }
  }

  function generateBoard(rows, cols, mines) {
    const board = Array(rows).fill().map(() => Array(cols).fill().map(() => ({ isMine: false, isOpen: false, isFlagged: false })));
    let minesPlaced = 0;
    while (minesPlaced < mines) {
      const row = Math.floor(Math.random() * rows);
      const col = Math.floor(Math.random() * cols);
      if (!board[row][col].isMine) {
        board[row][col].isMine = true;
        minesPlaced++;
      }
    }
    return board;
  }

  function countAdjacentMines(board, row, col) {
    const directions = [
      [-1, -1], [-1, 0], [-1, 1],
      [0, -1], [0, 1],
      [1, -1], [1, 0], [1, 1]
    ];
    let count = 0;
    directions.forEach(([dx, dy]) => {
      const newRow = row + dx;
      const newCol = col + dy;
      if (newRow >= 0 && newRow < ROWS && newCol >= 0 && newCol < COLS && board[newRow][newCol].isMine) {
        count++;
      }
    });
    return count;
  }

  function revealCell(board, row, col) {
    if (board[row][col].isOpen || board[row][col].isFlagged) return board;
    if (board[row][col].isMine) {
      setGameOver(true);
      setBoard(revealAllMines(board));
      setFace('💀');
      return;
    }
    const newBoard = board.map(row => row.map(cell => ({ ...cell })));
    const openCells = [];
    const openCell = (r, c) => {
      if (r >= 0 && r < ROWS && c >= 0 && c < COLS && !newBoard[r][c].isOpen && !newBoard[r][c].isFlagged) {
        newBoard[r][c].isOpen = true;
        OpenC = OpenC-1;
        const count = countAdjacentMines(newBoard, r, c);
        newBoard[r][c].adjacentMines = count;
        if (count === 0) {
          openCells.push([r, c]);
        }
      }
    };
    openCell(row, col);
    
    while (openCells.length) {
      const [r, c] = openCells.pop();
      const directions = [
        [-1, -1], [-1, 0], [-1, 1],
        [0, -1], [0, 1],
        [1, -1], [1, 0], [1, 1]
      ];
      directions.forEach(([dx, dy]) => openCell(r + dx, c + dy));
    }
    wincheck()
    return newBoard;
  }

  function revealAllMines(board) {
    setGameOver(true);
    setFace('💀');
    return board.map(row =>
      row.map(cell => ({
        ...cell,
        isOpen: cell.isMine ? true : cell.isOpen
      }))
    );
  }

  function revealAdjacentCells(board, row, col) {
    const directions = [
      [-1, -1], [-1, 0], [-1, 1],
      [0, -1], [0, 1],
      [1, -1], [1, 0], [1, 1]
    ];
    let flagCount = 0;
    directions.forEach(([dx, dy]) => {
      const newRow = row + dx;
      const newCol = col + dy;
      if (newRow >= 0 && newRow < ROWS && newCol >= 0 && newCol < COLS && board[newRow][newCol].isFlagged) {
        flagCount++;
      }
    });

    if (flagCount === board[row][col].adjacentMines) {
      let newBoard = board.map(row => row.map(cell => ({ ...cell })));
      let gameOver = false;

      directions.forEach(([dx, dy]) => {
        const newRow = row + dx;
        const newCol = col + dy;
        if (newRow >= 0 && newRow < ROWS && newCol >= 0 && newCol < COLS && !board[newRow][newCol].isFlagged && !board[newRow][newCol].isOpen) {
          if (newBoard[newRow][newCol].isMine) {
            gameOver = true;
          } else {
            newBoard = revealCell(newBoard, newRow, newCol);
          }
        }
      });

      return { newBoard, gameOver };
    }

    return { newBoard: board, gameOver: false };
  }

  const gotoLog = () => {
    navigate('/');
  };

  useEffect(() => {
    setBoard(generateBoard(ROWS, COLS, MINES));
    setGameOver(false);
    MineC = 200;
  }, []);

  const handleClick = (row, col) => {
    if (gameOver || board[row][col].isOpen || board[row][col].isFlagged) return;
    if (board[row][col].isMine) {
      setGameOver(true);
      setFace('💀');
      setBoard(revealAllMines(board));
      return;
    }
    const newBoard = revealCell(board, row, col);
    setBoard(newBoard);
  };

  const handleRightClick = (e, row, col) => {
    e.preventDefault();
    if (gameOver || board[row][col].isOpen) return;
    const newBoard = board.slice();
    newBoard[row][col].isFlagged = !newBoard[row][col].isFlagged;
    if(newBoard[row][col].isFlagged){
      MineC = MineC-1;
      OpenC = OpenC-1;
    }
    else{
      MineC = MineC+1;
      OpenC = OpenC+1;
    }
    setBoard(newBoard);
  };

  const restartGame = () => {
    setBoard(generateBoard(ROWS, COLS, MINES));
    setGameOver(false);
    MineC = MINES;
    OpenC = ROWS * COLS;
    setFace('😄');
  };

  const handleOpenCellClick = (row, col) => {
    if (board[row][col].isOpen && !board[row][col].isMine && board[row][col].adjacentMines > 0) {
      const { newBoard, gameOver } = revealAdjacentCells(board, row, col);
      setBoard(newBoard);
      if (gameOver) {
        setGameOver(true);
        setBoard(revealAllMines(board));
        return;
      }
    }
  };

  const handleMouseDown = () => {
    setFace('😰');
  };

  const handleMouseUp = () => {
    setFace('😄');
  };

  return (
    <div className='MSBG'>
      <h1>SUPER SECRET MINESWEEPER !!!</h1>
      <div className='MB'>
        <div className='buttonbox'>
          <button className='minebutton'>
            <span   role="img" 
                    onClick={gotoLog}  
                    aria-label="back">
                      Volver</span>
          </button>
          <button className='minebutton'>
            <span   role="img" 
                    onClick={restartGame}  
                    aria-label="Smile">
                      {face}</span>
          </button>
          <button className='minebutton'>
            <span   role="img" 
                    aria-label="Bomb">
                      💣:{MineC}</span>
          </button>
          {/* <button><span role="img" aria-label="Space">⬜:{OpenC}</span></button> */}
        </div>
        <div  className="board"
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}>
          {board.map((row, rowIndex) => (
            <div key={rowIndex} className="rowMS">
              {row.map((cell, colIndex) => (
                <div
                key={colIndex}
                className={`cell ${cell.isOpen || cell.isFlagged ? 'open' : ''}`}
                onClick={() => {
                  if (gameOver){
                    restartGame();
                  }
                  else{
                    if (cell.isOpen) {
                      handleOpenCellClick(rowIndex, colIndex);
                    } 
                    else {
                      handleClick(rowIndex, colIndex);
                    }
                  }
                }}
                onContextMenu={(e) => handleRightClick(e, rowIndex, colIndex)}
              >
                  {cell.isFlagged ? '🚩' : ''}
                  {cell.isOpen && cell.isMine ? '💥' : ''}
                  {cell.isOpen && !cell.isMine && cell.adjacentMines > 0 ? (
                    <strong>
                      <span className={`number-${cell.adjacentMines}`}>{cell.adjacentMines}</span>
                    </strong>
                  ) : ''}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
