import './header.css';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Chatbox from './Chatbox';

function logout() {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('chatMessages');
    window.location.replace("https://renee.cl");
};

const namestring = sessionStorage.getItem('token');

const Header = () => {
    const [showChatbox, setShowChatbox] = useState(false);

    const navigate = useNavigate();
    let location = useLocation();
    location = location.pathname;

    const goto01 = () => {
        navigate('/MisProcesos');
    };

    const toggleChatbox = () => {
        setShowChatbox(!showChatbox);
    };

    return (
        <header className="header">
            <div className="titles">
                <img 
                    src="/favicon.png" 
                    alt="favicon" 
                    className="logo" 
                    onClick={goto01}
                />
                &nbsp;  
                {location === "/MisProcesos" || location === '/CProceso'|| location === '/NProceso' || location === '/'?(
                    <h3 className="title">Bienvenido a Reneé || {namestring.toUpperCase()}</h3>
                ):
                (
                    <h3 className="title">{sessionStorage.getItem('name').toUpperCase() || 'Error'}</h3>           
                )}
                &nbsp;  
                <div className="buttons">
                    <button className="small" onClick={toggleChatbox}><span role="img" aria-label='Chat'>💬</span></button>
                    <button className="button" onClick={goto01}>Inicio</button>
                    <button className="button" onClick={logout}>Logout</button>
                </div>
            </div>
            <div className="subtitle">
                <button className={location === "/" || location === '/MisProcesos' ? 'sub-box-on' : 'sub-box-off'}>
                    <h3>Tus Procesos</h3>
                </button>
                <button className={location === "/CProceso" ? 'sub-box-on' : 'sub-box-off'}>
                    <h3>Crear Proceso</h3>
                </button>
                <button className={location === "/Notas" ? 'sub-box-on' : 'sub-box-off'}>
                    <h3>Levantamiento de Perfil</h3>
                </button>
                <button className={location === "/EProceso" ? 'sub-box-on' : 'sub-box-off'}>
                    <h3>Editar Datos</h3>
                </button>
                <button className={location === "/GenPerfil" ? 'sub-box-on' : 'sub-box-off'}>
                    <h3>Perfil de Búsqueda</h3>
                </button>
                <button className={location === "/GenOferta" ? 'sub-box-on' : 'sub-box-off'}>
                    <h3>Aviso de Empleo</h3>
                </button>
                <button className={location === "/Candidatos" ? 'sub-box-on' : 'sub-box-off'}>
                    <h3>Revision de candidatos</h3>
                </button>
                <button className={location === "/Formulario" ? 'sub-box-on' : 'sub-box-off'}>
                    <h3>Formulario Pre-Screening</h3>
                </button>
            </div>
            {showChatbox && <Chatbox onClose={toggleChatbox} />}
        </header>
    );
};

export default Header;
