
//=============================================================================
// Pantalla Lista de Procesos
//=============================================================================

import './css/App.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import APICALL from './APICALL';

export default function Pprocess() {

	const navigate = useNavigate();
	const [replies, setReplies] 						= useState([]); 
	const [loading, setLoading] 						= useState(false);
	const [fetchedData, setFetchedData] 		= useState(''); 
	const [Check, setCheck] 								= useState(false);
	const [Warning, setWarning] 						= useState(false);
	const [error, setError] 								= useState(''); 
	const [selectedReply, setSelectedReply] = useState('')

	let [content] 	= useState([]); 
	let [values] 		= useState([]); 
	let [grupos] 		= useState([]); 

	
	async function List(){
		setLoading(true)
		content = [];
		const process = await APICALL({
			apicall: 'PD_list',
			R_name: sessionStorage.getItem('token'),
			U_emp: sessionStorage.getItem('Grupo')
		});

		if (process && Array.isArray(process)) {
			let i = 0;
			for( i in process.sort((a, b) => a[2].localeCompare(b[2]))){
				if (grupos.indexOf(process[i][1]) === -1){
					grupos.push(process[i][1])
				}
				if (process[i][1] === sessionStorage.getItem('Grupo')){
					values[i] = (process[i][0]);
					content[i] = (process[i][2]);
				}
			}
			sessionStorage.setItem('gruposlista' , JSON.stringify(grupos));

			const transformedReplies = content.map(reply => reply.replace(/_/g, ' | '));
			setReplies(transformedReplies);
			setTimeout(() => {
				setLoading(false);
			}, 20 * 1000);
		} else {
			setError(content.error || ' '); 
			setReplies([]); 
		}
		setLoading(false)
	}

	async function FetchData(){
		const data = await APICALL({
			apicall :'PD_fetch' ,
			code :sessionStorage.getItem("process"),
			U_emp: sessionStorage.getItem('Grupo')
		});
		sessionStorage.setItem('perfil' , data.GPTreply)
		setFetchedData(data);
	}

	const Handlecheck = async (value,reply) => {
		setSelectedReply(value);
		if (value === selectedReply) {
			setSelectedReply('')
			sessionStorage.removeItem("process");
			sessionStorage.removeItem("name");
			setFetchedData('')
			setCheck(false)
		} else {
			let transformedback = value.replace(/ \| /g, '_');
			sessionStorage.removeItem("process");
			sessionStorage.removeItem("name");
			sessionStorage.setItem("process", transformedback);
			sessionStorage.setItem("name", reply);
			await setFetchedData(FetchData());
			setCheck(true)
		}
	}

	const DropProcess = async () => {
		setLoading(true)
		await APICALL({
			apicall :'PD_drop' ,
			code : sessionStorage.getItem("process"),
			R_name : fetchedData.R_name, 
			U_emp: sessionStorage.getItem('Grupo')
		});
		List()
		setCheck(false)
		setLoading(false)
		setWarning(false)
	}

	const DataItems = [
    { label: 'EMPRESA',                   value: fetchedData.E_name 			? fetchedData.E_name.toUpperCase() : ' - - - ' }, 
    { label: 'CARGO',                     value: fetchedData.Charge_name 	? fetchedData.Charge_name.toUpperCase() : ' - - - ' }, 
		{ label: 'HEAD HUNTER',               value: fetchedData.R_name 			? fetchedData.R_name.toUpperCase() : ' - - - ' }, 
    { label: 'INTERLOCUTOR',              value: fetchedData.I_name 			? fetchedData.I_name.toUpperCase() : ' - - - ' },
		{ label: 'ID',              					value: fetchedData.JobId  			? fetchedData.JobId : ' - - - ' },
		{ label: 'FECHA LIMITE',              value: fetchedData.Timewith 		? fetchedData.Timewith.toUpperCase() : ' - - - ' }
  ]

	const Goto02 = () => { navigate('/CProceso') }
	const Goto05 = () => { navigate('/Notas') }
	const Goto07 = () => { navigate('/Candidatos') }

	useEffect(() => {
		sessionStorage.removeItem('process')
		setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2 * 1000);
		List();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="BackGround">	
			<div 	onClick={() => setWarning(false)}
						className={ Warning? "WarningBG" : "hidden"}>
					<div className= { Warning? "warningbox" : "hidden"}>
						¿Esta Seguro que quiere Eliminar el Proceso?
						<div>
							<button onClick={DropProcess}>Eliminar</button>
							<button onClick={() => setWarning(false)}>Cancelar</button>
						</div>
					</div>
			</div>
			<div className="typing-effect-container">
				<h2 className="typing-effect" >Hey Vamos a trabajar! , Indícame si quieres trabajar sobre un proceso existente o si quieres crear uno nuevo </h2>
			</div>		

				<div className='MainBody'>

				<div className="ColumnCenter">
					<h3>Tus Procesos</h3>
						<div className='box3'>
							&nbsp;
							<button onClick={List}>Actualizar la lista</button>
							<button onClick={Goto02}>Nuevo proceso</button>
							&nbsp;
						</div>
					<div className='mainselect'>
						{loading ? (
							<div className="loader-container">
								<div className="pulsing-dot">
									<div></div>
									<div></div>
									<div></div>
								</div>
							</div>
						) 
						: (		
							<div>
								{replies.length > 0 ? 
									replies.map((reply, index) => (
										<div key={index} style={{ display: 'flex', alignItems: 'center' }}>
											<button 
												onClick={() => Handlecheck(values[index], reply)} 
												className={values[index] === selectedReply ? 'highlighted' : ''}
											>
												{reply.toUpperCase()}
											</button>
										</div>
									)) : 
									<button>
										Sin procesos Activos
									</button>
								}
							</div>
						)}
					</div>
					{error && <div className="error">{error}</div>}
				</div>
				
					<div className="ColumnCenter">
						<h3>Datos de tu proceso</h3>
						{Check ? (
						<div className="box3">
							&nbsp;
							<button onClick={Goto05}>Continuar Proceso</button>
							<button onClick={Goto07}>Continuar a Candidatos</button>
							{/* <button onClick={goto03}>Editar Proceso</button> */}
							<button onClick={() => setWarning(true)}>Eliminar Proceso</button>
						</div>
						) 
						: (		
						<div className="box3">
							&nbsp;
							<button style={{color:'#60679600',backgroundColor:'#60679600'}}>Continuar Proceso</button>
							<button style={{color:'#60679600',backgroundColor:'#60679600'}}>Continuar a Candidatos</button>
							<button style={{color:'#60679600',backgroundColor:'#60679600'}}>Eliminar Proceso</button>
						</div>
						)}
						<div className='mainselect'>
							<div className={Check ? "data-boxes2" : ' hidden' }>
								{DataItems.map((item, index) => (
									<div key={index} className="data-box">
										<strong>{item.label}:</strong> {item.value}
									</div>
								))}
							</div>
							<div className={Check ? "hidden" : '' }>
								<h3>Por favor elija un proceso</h3>
							</div>
						</div>
					</div>
				</div>
		</div>
	);
}
