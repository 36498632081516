
//=============================================================================
// Pantalla Nuevo Proceso
//=============================================================================

import './css/App.css';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import APICALL from './APICALL';

export default function Nopciones() {

  const navigate = useNavigate();
  const [Elista, setELista] = useState([]);
  const [Ilista, setILista] = useState([]);

  const [Charge_name, setCharge_name] = useState('');

  const [E_rut, setE_rut]           = useState('');
  const [E_social, setE_social]     = useState('');
  const [E_name, setE_name]         = useState('');
  const [E_giro, setE_giro]         = useState('');
  const [E_contacto, setE_contacto] = useState('');
  const [E_correo, setE_correo]     = useState('');

  const [I_name, setIname] = useState('');
  const [I_mail, setImail] = useState('');

  const [wait, setwait]   = useState(true);
  const [wait2, setwait2] = useState(false);
  const [wait3, setwait3] = useState(false);
  const [wait4, setwait4] = useState(false);

  const [Nempresa, setNempresa]   = useState(false);
  const [Ninter, setNinter]       = useState(false);

  const code = Date.now() + '_' + E_name + '_' + I_name + '_' + Charge_name;
  const P_name = E_name + '_' + Charge_name;

  async function FetchDataE() {
    const data = await APICALL({
      apicall: 'E_list'
    });
    setELista(data.filter(x => x));
    return data.filter(x => x)
  }

  async function FetchDataI(name) {
    const data = await APICALL({
      apicall: 'I_list',
      E_name: name
    });
    setILista(data.filter(x => x));
    return data.filter(x => x)
  }

  function ChangeEmp(){
    if (Nempresa === true){
      setNempresa(false);
    } else {
      setNempresa(true);
    }
  }

  function ChangeInt(){
    if (Ninter === true){
      setNinter(false);
    } else {
      setNinter(true);
    }
  }

  const Empprocess = async (reply) => {

    setwait(true)
    sessionStorage.removeItem('Empresa');
    sessionStorage.setItem('Empresa', reply);
    const interlocutors = FetchDataI(reply);
    setILista(interlocutors);
    setE_name(reply);
    setwait(false);
  }

  const HandleEmp = async e => {

    function dgv(T) {
      var M = 0;
      var S = 0;
      var multipliers = [2, 3, 4, 5, 6, 7];
      
      while (T > 0) {
        var digit = T % 10;
        S += digit * multipliers[M % 6];
        M++;
        T = Math.floor(T / 10);
      }
      
      var remainder = 11 - (S % 11);
      
      if (remainder === 11) return 0;
      if (remainder === 10) return 'k';
      return remainder;
    }

    if (dgv(E_rut.slice(0,-2)) === Number(E_rut.slice(-1))){
      setwait2(true);
      e.preventDefault();
      await APICALL({
        apicall: 'E_create',
        E_rut,
        E_social,
        E_name,
        E_giro,
        E_contacto,
        E_correo
      });
      await APICALL({
        apicall: 'I_create',
        E_name,
        I_name: E_contacto,
        I_mail: E_correo
      });
      FetchDataE();
      Empprocess(E_name);
      setwait2(false);
    } else {
      alert('rut invalido');
    }
  }

  const HandleInt = async e => {
    setwait3(true);
    e.preventDefault();
    // const process = 
    await APICALL({
      apicall: 'I_create',
      E_name,
      I_name, // nombre de la empresa
      I_mail // mail del interlocutor
    });
    FetchDataI(E_name);
    setwait3(false);
    setIname('');
    setImail('');
  }

  const Goto01 = () => { navigate('/MisProcesos') }
  const Goto03 = async () => {
    try {
      const data = await APICALL({
        apicall: 'PD_create',
        code,
        P_name,
        E_name,
        I_name,
        R_name : sessionStorage.getItem('token'),
        U_emp : sessionStorage.getItem('Grupo'),
        Charge_name
  
      });
      if (data){
        sessionStorage.removeItem("process");
        sessionStorage.setItem("process", code);
        sessionStorage.setItem("name", Charge_name);
      } 
      navigate('/Notas');
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
    
  }

  useEffect(() => {
    async function Updatelista() {
      const lista = await FetchDataE();
      setELista(lista);
    };
    Updatelista();
  }, [])

  return (
    <div className="BackGround">
      <div className="typing-effect-container">
				<h2 className="typing-effect" >Ahora proporcioname información para asistirte en el proceso que liderarás!</h2>
			</div>
      <div className="MainBody">

        <div className='ColumnLeft'>
          <div className="row">
          <h2> Selecciona La Empresa </h2>

            <div className="column">
                  {Elista.length > 0 ? (
                    <select size="13" 
                            className='bigselect' 
                            onChange={(e) => {Empprocess(e.target.value); setwait4(false)}}>
                      {Elista.sort().map((reply, index) => (
                        <option key={index} value={reply}>
                          {reply.toUpperCase()}
                        </option>
                      ))}
                    </select>
                  ) 
                  : (
                    <button>Lista de Empresas Vacia</button>
                  )}
            </div>

            <div className='column'>
            <button onClick={ChangeEmp}>Nueva Empresa</button>
              <div className={Nempresa === false ? 'hidden' : 'boxCenter'}>
                <form onSubmit={HandleEmp}>

                  <input type="text" placeholder='Rut de la empresa'
                  onChange={e => setE_rut(e.target.value)} required={true} />

                  <input type="text" placeholder='Razón Social'
                    onChange={e => setE_social(e.target.value)} required={true} />

                  <input type="text" placeholder='Alias de la empresa'
                    onChange={e => setE_name(e.target.value)} required={true} />

                  <input type="text" placeholder='Giro'
                    onChange={e => setE_giro(e.target.value)} required={true} />
                    
                  <input type="text" placeholder='Contacto de la empresa'
                    onChange={e => setE_contacto(e.target.value)} required={true} />

                  <input type="email" placeholder='Correo'
                    onChange={e => setE_correo(e.target.value)} required={true} />

                  <button type="submit" >Guardar</button>

                </form>
                {wait2 === false ? (
                  <div>
                    <br />
                  </div>
                ) 
                : (
                  <div className="loader-container">
                    <div className="pulsing-dot">
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                )}
              </div>
            </div>

          </div>
        </div>

        <div className='ColumnLeft'>
          {wait ? (
            <h1> </h1>
          ) 
          : (
            <div className="row">
            <h2>Selecciona el Interlocutor</h2>

              <div className="column">
                  {Ilista.length > 0 ? (
                    <select size="13" className='bigselect' onChange={(e) => {setIname(e.target.value); setwait4(true)}}>
                      {Ilista.sort().map((reply, index) => (
                        <option key={index} value={reply}>
                          {reply.toUpperCase()}
                        </option>
                      ))}
                    </select>
                  ) 
                  : (
                    <button>No existen interlocutores</button>
                  )}
              </div>

              <div className="column">
              <button onClick={ChangeInt}>Nuevo/a Interlocutor/a</button>
                <div className={Ninter === false ? 'hidden' : 'boxCenter'}>
                  
                  <form onSubmit={HandleInt}>
                    <input type="text" placeholder='Nombre del interlocutor'
                    onChange={e => setIname(e.target.value)} required={true} />

                    <input type="email" placeholder='Correo'
                    onChange={e => setImail(e.target.value)} required={true} />

                    <button  type="submit">Guardar</button>
                  </form>
                  
                  {wait3 === false ? (
                    <div>
                      <br />
                    </div>
                  ) 
                  : (
                    <div className="loader-container">
                      <div className="pulsing-dot">
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className='Columnshort'>
         {!wait4 ? (
            <h1> </h1>
          ) 
          : (
            <div>
              <h2>Asigne un Nombre al Proceso</h2>
              <div>
              <label><strong>NOMBRE DEL CARGO *</strong>
                <input  type="text" 
                        placeholder= 'Ingrese el Nombre del cargo'
                        className= 'formbox'
                        onChange={e => setCharge_name(e.target.value)} 
                        required={true} />
              </label>
              </div>
            </div>
          )}
        </div>

      </div>

      <div className='bottom'>
        <button onClick={Goto01}>volver</button>
        <div></div>
        <div></div>
        <div></div>
        <button className={Charge_name === '' || I_name === '' ? 'hidden' : ''} onClick={Goto03}>Continuar</button>
      </div>
    </div>
  );
}
