import React, { useState, useEffect, useRef } from 'react';
import AICALL from '../functions/AICALL';
import './Chatbox.css';

export default  function Chatbox() {

  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  
  const chatboxBodyRef = useRef(null);

  const initialWelcomeMessage = [
    { sender: 'gpt', text: 'Hola!, soy Reneé \nen que puedo ayudarte hoy?' }
  ];

  const sendMessage = async () => {
    if (input.trim() === '') return;

    const userMessage = { sender: 'user', text: input };
    const updatedMessages = [...messages, userMessage];
    setMessages(updatedMessages);
    sessionStorage.setItem('chatMessages', JSON.stringify(updatedMessages));
    sessionStorage.setItem('chatLoading', 'true');
    setInput('');
    setLoading(true);

    try {
      const data = await AICALL({
        apicall: '04',
        data: input
      });
      const gptMessage = { sender: 'gpt', text: data.reply };
      const finalMessages = [...updatedMessages, gptMessage];
      setMessages(finalMessages);
      sessionStorage.setItem('chatMessages', JSON.stringify(finalMessages));
      sessionStorage.setItem('chatLoading', 'false');
      setLoading(false);

    } catch (error) {
      console.error('Error during fetch:', error);
      sessionStorage.setItem('chatLoading', 'false');
      setLoading(false);
    }
  };

  const handlereset = () => {

    sessionStorage.removeItem('chatMessages');
    sessionStorage.removeItem('chatLoading');
    setMessages([]);
    setInput('');
    setMessages(initialWelcomeMessage);
    sessionStorage.setItem('chatMessages', JSON.stringify(initialWelcomeMessage));
    setLoading(false);
  };

  useEffect(() => {
    const savedMessages = JSON.parse(sessionStorage.getItem('chatMessages')) || [];
    const savedLoading = sessionStorage.getItem('chatLoading') === 'true';
    
    if (savedMessages.length === 0) {
      setMessages(initialWelcomeMessage);
      sessionStorage.setItem('chatMessages', JSON.stringify(initialWelcomeMessage));
    } else {
      setMessages(savedMessages);
    }
    
    setLoading(savedLoading); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (chatboxBodyRef.current) {
      chatboxBodyRef.current.scrollTop = chatboxBodyRef.current.scrollHeight;
    }
  }, [messages]);

  const formatText = (text) => {
    return text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  };

  return (
    <div className="BG">
      <div className="chatbox">
        <div className="chatbox-header">
          <h3>Ayuda Reneé <span role="img" aria-label='Chat'>💬</span></h3>
        </div>
        <div className="chatbox-body" ref={chatboxBodyRef}>
          {messages.map((msg, index) => (
            <div key={index} className={`message ${msg.sender}`}>
              <div dangerouslySetInnerHTML={{ __html: formatText(msg.text) }} />
            </div>
          ))}
          {loading && (
            <div className="loading">
              <span>•</span><span>•</span><span>•</span>
            </div>
          )}
        </div>
        <div className="chatbox-footer">
          <input
            className='cf-input'
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && sendMessage()}
          />
          <button 
            className='cf-button'
            onClick={sendMessage}>Enviar
          </button>
          <button 
            className='cf-button'
            onClick={handlereset}>Borrar Todo
          </button>
        </div>
      </div>
    </div>
  );
};

