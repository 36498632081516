import './css/App.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import APICALL from './APICALL';
import AICALL from './AICALL';

export default function Recuperar() {

  const navigate = useNavigate();
  const [CV, setCV] = useState(null);
  const [CVlist, setCVlist] = useState([]);
  const [selectlist, setselectlist] = useState([]);
  const [selectedCVId, setSelectedCVId] = useState(null);
  const [sortType, setSortType] = useState('score');
  const [wait, setwait] = useState(false);


  const goto01 = () => { navigate('/MisProcesos') };
  const goto08 = () => { navigate('/Formulario') };

  const handleClick = () => {
    const input = prompt("Ingrese el ID del proceso:");
    if (input !== null) {
      setCV(null); 
      handlecandidatos(input); ;
    }
  };

  const sortCVlist = () => {
    const sortedList = [...CVlist].sort((a, b) => {
      if (sortType === 'score') {
        const notaA = a.Nota ? JSON.parse(a.Nota) : 0;
        const notaB = b.Nota ? JSON.parse(b.Nota) : 0;
        return notaB - notaA; 
      } else {
        return a.name.localeCompare(b.name); 
      }
    });
    return sortedList;
  };

  async function handleReneeopinion(CV,name) {

    const instruction = 'EL Perfil: ' + sessionStorage.getItem('perfil') 
                 + '. \n El candidato: ';
    
    try {
      const consulta = await AICALL({
        apicall: '05',
        data: instruction + JSON.stringify(CV.personalInfo) + JSON.stringify(CV.workExperience) + JSON.stringify(CV.studies)
      });
    
      if (consulta.Respuesta.toUpperCase() === 'NO') {
        setCVlist(prevCVlist =>
          prevCVlist.map(cv =>
            cv.name === name
              ? { ...cv, respuesta: 'NO', Nota: consulta.Evaluacion } 
              : cv
          )
        );
        return(consulta.Evaluacion);
      } else {
        setCVlist(prevCVlist =>
          prevCVlist.map(cv =>
            cv.name === name
              ? { ...cv, respuesta: 'SI', Nota: consulta.Evaluacion } 
              : cv
          )
        );
        return(consulta.Evaluacion);
      }
    
    } catch (error) {
      console.error('Error during fetch:', error);
      handleReneeopinion(CV,name)
    }
    
  }

  async function Handleselect(name) {
    setCVlist(prevCVlist =>
      prevCVlist.map(cv =>
        cv.name === name
          ? { ...cv, select: !cv.select} 
          : cv
      )
    );
  
    const updatedSelectList = selectlist.includes(name)
      ? selectlist.filter(id => id !== name)
      : [...selectlist, name];
  
    setselectlist(updatedSelectList);
    
    console.log(updatedSelectList); 
  
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo'),
        SelectList: updatedSelectList 
      });
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
  }
  
  async function handlecandidatos(data) {
    try {
      const reply = await APICALL({
        apicall: 'TB_list',
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo'),
        jobID: data
      });
  
      let list = [];
      if (reply) {
        list = reply.data.sharedPostulations || [];
        const processedIds = new Set();
  
        const cvPromises = list.map(async (applicant) => {
          if (!processedIds.has(applicant.applicantId)) {
            processedIds.add(applicant.applicantId);
            await handleCV(applicant.applicantId);
          }
        });
  
        await Promise.all(cvPromises);
      }
    } catch (error) {
      console.error('Error during fetch:', error);
    }
  }

  async function lookCV(data) {
    try {
      const reply = await APICALL({
        apicall: 'TB_fetch',
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo'),
        id_code: data
      });
  
      const { personalInfo, socialNetworks, workExperience, presentation, studies } = reply.data;
      const cv = { personalInfo, socialNetworks, workExperience, presentation, studies };

      setCV(cv);
  
    } catch (error) {
      console.error('Error during fetch:', error);
    }
  }

  async function handleCV(data) {
    setwait(true);
  
    try {
      const reply = await APICALL({
        apicall: 'TB_fetch',
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo'),
        id_code: data
      });
  
      const { personalInfo, socialNetworks, workExperience, presentation, studies } = reply.data;
      const name = `${personalInfo.firstName} ${personalInfo.lastName}`;
      const cv = { personalInfo, socialNetworks, workExperience, presentation, studies };
  
      setCVlist(prevCVlist => {
        const existingCV = prevCVlist.find(cv => cv.name === name);
  
        if (!existingCV) {
          const updatedList = [...prevCVlist, { name: name, id: data }];
          handleReneeopinion(cv, name).then(nota => {
            saveCV(name, data, nota);
          });
          return updatedList;
        } else {
          handleReneeopinion(cv, name).then(nota => {
            saveCV(name, data, nota);
          });
          return prevCVlist;
        }
      });
  
    } catch (error) {
      console.error('Error during fetch:', error);
    } finally {
      setwait(false);
    }
  }
  
  
  async function saveCV(Namedata, id, score) {
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo'),
        CVData: { name: Namedata, id: id, Nota: score}
      });
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
  }

  function formatJobData(jobData) {

    const title = '<h2> Experiencia Laboral: </h2>\n\n';

    const formattedjobs =  jobData.map(job => {
      return `
        <strong> ${job.jobPosition ? job.jobPosition.toUpperCase() : '' } </strong>${job.companyName} || ${job.companyActivity.description} \n ${job.fromDate} - ${job.toDate || 'Presente'}
        <strong>RESPONSABILIDADES</strong> ${job.achievements}
      `.trim();
    }).join('\n\n __________ \n\n');

    return `${title}${formattedjobs}`;
  }

  function formatStudyData(studyData) {

    const title = '<h2> Formación Académica: </h2>\n\n';
  
    const formattedStudies = studyData.higherEducation.map(study => {
      return `
        <strong>${study.otherCareer ? study.otherCareer.toUpperCase() : ''}${study.minor ? ' | ' + study.minor.toUpperCase() : ''}</strong>${study.otherInstitution} \n ${study.statusName} \n ${study.entryYear} ~ ${study.graduationyear || 'Presente'}
      `.trim();
    }).join('\n\n __________ \n\n');
  
    return `${title}${formattedStudies}`;
  }
  
  function formatPersonalData(PD) {

    const [day, month, year] = PD.personalInfo.birthDate.split('/').map(Number);
    const birthdate = new Date(year, month - 1, day);
    const today = new Date();
    
    let age = today.getFullYear() - birthdate.getFullYear();
    const monthDiff = today.getMonth() - birthdate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthdate.getDate())) {
      age--;
    }

    return `
      <strong>${PD.personalInfo.firstName} ${PD.personalInfo.lastName}, ${age} años </strong>\n${PD.presentation || 'Sin Descripción'}

      Facebook: 
      ${PD.socialNetworks.facebookLink || '- - -'} 

      Twitter: 
      ${PD.socialNetworks.twitterLink || '- - -'}       

      Linked In: 
      ${PD.socialNetworks.linkedinLink ? `<a href="${PD.socialNetworks.linkedinLink}" target="_blank" rel="noopener noreferrer">${PD.socialNetworks.linkedinLink}</a>` : '- - -'}

      Web Blog: 
      ${PD.socialNetworks.webBlogLink ? `<a href="${PD.socialNetworks.webBlogLink}" target="_blank" rel="noopener noreferrer">${PD.socialNetworks.webBlogLink}</a>` : '- - -'} 

      🏠 ${PD.personalInfo.communeName}
      Region ${PD.personalInfo.regionName}, ${PD.personalInfo.residenceCountry.description}

      ☎️ ${PD.personalInfo.phoneNumbers[0].number || 'N/A'}
      ✉️ ${PD.personalInfo.emails.primaryEmail || 'N/A'}
    `.trim();
  }

  useEffect(() => {
    const fetchData = async () => {
      const data = await APICALL({
        apicall: 'PD_fetch',
        code: sessionStorage.getItem("process"),
        U_emp: sessionStorage.getItem('Grupo')
      });
      localStorage.removeItem('fetched');
      localStorage['fetched'] = data;
      if (data.CVData){
        setCVlist(data.CVData);
        setselectlist(Array.isArray(data.SelectList) 
        ? data.SelectList        
        : data.SelectList             
          ? [data.SelectList]        
          : []);
      }
      if (data.JobId){
        handlecandidatos(data.JobId);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="BackGround">
      <div className="typing-effect-container">
        <h2 className="typing-effect">
          Hey, revisemos los candidatos que has recibido para tu proceso!
        </h2>
      </div>
      <div className='MainBody'>
        <div className='boxfit' style={{ maxWidth: '20vw' }}>
          <div style={{display:'flex'}}>
            <h3 className='boxtitle'>Lista de Candidatos</h3> 
            <button className='sortbutton'
                    onClick={() => setSortType(sortType === 'score' ? 'name' : 'score')}>
              {sortType === 'score' ? 'Puntaje (desc)' : 'Nombre (A-Z)'}
            </button>
          </div>
          <div className='notas'>
          {CVlist && CVlist.length > 0 ? (
            sortCVlist().map((reply, index) => (
              <div key={reply.name} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <button
                  className={ selectlist.includes(reply.name) ? 'green-button' : 
                                              reply.name === selectedCVId ? 'highlighted-button' : 
                                                                            'normal-button'}
                  onClick={() => {
                    lookCV(reply.id);
                    setSelectedCVId(reply.name);
                  }}
                >
                  {reply.name}
                </button>
                <button className={ !reply.Nota ? 'normal-button' : 
                                                  JSON.parse(reply.Nota) < 50 ? 'red-button' : 
                                                                                JSON.parse(reply.Nota) < 70 ? 'yellow-button' : 
                                                                                                              'blue-button'}
                        style={{maxWidth:'4vw'}}
                        onClick={() => {
                          lookCV(reply.id);
                        }}>
                  {reply.Nota ? JSON.parse(reply.Nota) : ' - '}{'%'}
                </button>
                <input  type="checkbox" 
                        className='select-checkbox'
                        checked={selectlist ? selectlist.includes(reply.name) : false}
                        onChange={() => {
                          Handleselect(reply.name);
                        }}></input>
              </div>
            ))
          ) : (
            <button>No hay candidatos</button>
          )}
          </div>
        </div>
        &nbsp;
        <div className='boxfit' style={{ maxWidth: '20vw' }}>
          <h3 className='boxtitle'>Perfil personal</h3>
          <div className='notas' style={{ overflow: 'hidden' }}>
            <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
              <img
                src={CV && CV.personalInfo.picture ? CV.personalInfo.picture : '/default.png'}
                alt="Profile"
                style={{
                  width: '150px',
                  height: '150px',
                  objectFit: 'cover',
                  borderRadius: '1vh',
                  position:'stick'
                }}
              />
              <div
                className="notas-nested"
                dangerouslySetInnerHTML={{ __html: CV ? formatPersonalData(CV) : '' }}
                style={{
                  whiteSpace: 'pre-wrap'
                }}
              />
            </div>
          </div>
        </div>
        &nbsp;
        <div className='boxfit'>
          <h3 className='boxtitle'>Perfil del candidato</h3>
          <div
            className="notas"
            dangerouslySetInnerHTML={{ __html: CV ? formatJobData(CV.workExperience) 
                                                  + '\n\n\n\n'
                                                  + formatStudyData(CV.studies) : '' }}
            style={{
              whiteSpace: 'pre-wrap',
              overflowY: 'auto',
              textAlign: 'left'
            }}
          />
        </div>
      </div>
      <div className='bottom'>
        <button onClick={goto01}>volver</button>
        <button onClick={handleClick}>Buscar por ID</button>
        <button onClick={() => handleReneeopinion(CV,selectedCVId)}>Consulta Manual a Renee</button>
        <div style={{marginTop:'-1vh', height:'1vh'}}>{wait === false ? (
              <div>
              </div>
            ) 
            : (
              <div className='box'>
                <div className="loader-container">
                  <div  className="pulsing-dot">
                  <div></div>
                  <div></div>
                  <div></div>
                  </div>
                </div>
              </div>
            )}</div>
        <button onClick={goto08}>Continuar a Formularios</button>
      </div>
    </div>
  );
}
