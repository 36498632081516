
//=============================================================================
// Pantalla Oferta / Preguntas generadas GPT
//=============================================================================

import './css/App.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jsPDF } from 'jspdf';
import APICALL from './APICALL';
import AICALL from './AICALL';

export default function Public() {

  const navigate = useNavigate();
  const [oferta, setOferta] = useState('');
  const handleChange = (event) => { setOferta(event.target.value); };
  const [fetchedData, setFetchedData] = useState(''); 
  const [pregunta1, setPregunta1] = useState('');
  const [pregunta2, setPregunta2] = useState('');
  const [pregunta3, setPregunta3] = useState('');
  const [pregunta4, setPregunta4] = useState('');
  const [Final1, setFinal1] = useState('');
  const [Final2, setFinal2] = useState('');
  const [Final3, setFinal3] = useState('');
  const [Final4, setFinal4] = useState('');
  const [wait, setwait] = useState(false);
  const [Publicar, setPublicar] = useState(false);
  const [regen, setregen] = useState(false);

  const notas1 = 'Genera El aviso como un proceso de busqueda para la empresa' 
                  + sessionStorage.getItem('grupo')
                  + 'Mantieniendop un lenguaje del estilo "nuestra empresa"';

  const notas2 = 'Genera El aviso como un proceso de busqueda para una empresa externa' 
                  + 'Mantieniendop un lenguaje formal del estilo "la empresa"';

  const notas3 = 'Genera El aviso como un proceso de busqueda para una empresa incognita' 
                  + 'evita cualquier mencion del nombre de la empresa';

  const goto05 = () => {
    navigate('/GenPerfil');
  };

  const goto07 = () => {
    navigate('/Candidatos');
  };

  const fetchData = async () => {
    const data = await APICALL({
      apicall :'PD_fetch' ,
      code :sessionStorage.getItem("process"),
      U_emp: sessionStorage.getItem('Grupo')
    });
    localStorage.removeItem('fetched');
    localStorage.setItem('fetched', data);
    setOferta(data.GPToferta);
    setPregunta1(data.GPTpreguntas);
    if (!data.GPToferta){
      GenerateOferta();
    } 
    if (!data.GPTpreguntas){
      GeneratePreguntas();
    }
  };

  async function GenerateOferta(notas) {
    setwait(true);
    setOferta('Renee esta trabajando en su respuesta ...')
    try {
      const newText = await AICALL({
        apicall: '02',
        data: localStorage.getItem('reply') 
              + localStorage.getItem('fetched')
              + notas
      });
      setOferta(newText.reply);
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
    setwait(false);
  }

  async function exportToTrabajando() {
    setwait(true);
    try {
      const reply = await APICALL({
        apicall: 'TB_create',
        code: sessionStorage.getItem('process'),
        user: sessionStorage.getItem('token'),
        mail: sessionStorage.getItem('mail'),
        U_emp: sessionStorage.getItem('Grupo')
      });
      window.open(reply.url);
      SaveJobId(reply.id);
    } catch (error) {
      console.error('Error during fetch:', error);
    }
    setwait(false);
  }

  async function GeneratePreguntas() {
    setwait(true);
    if (!Final1){
      setPregunta1('Renee esta trabajando en su respuesta ...')
    }
    if (!Final2){
      setPregunta2('Renee esta trabajando en su respuesta ...')
    }
    if (!Final3){
      setPregunta3('Renee esta trabajando en su respuesta ...')
    }
    if (!Final4){
      setPregunta4('Renee esta trabajando en su respuesta ...')
    }
    
    try {
      const newText = await AICALL({
        apicall: '03',
        data: localStorage.getItem('reply') 
              + localStorage.getItem('fetched')
      });
      if (!Final1){
        setPregunta1(newText.pregunta1);
      }
      if (!Final2){
        setPregunta2(newText.pregunta2);
      }
      if (!Final3){
        setPregunta3(newText.pregunta3);
      }
      if (!Final4){
        setPregunta4(newText.pregunta4);
      }
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
    setwait(false);
  }

  async function SaveOferta() {
    setwait(true);
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.getItem('process'), 
        U_emp: sessionStorage.getItem('Grupo'),
        oferta: oferta,
      });
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }
    setwait(false);
  };

  async function SavePregunta() {
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo'), 
        preguntas: [Final1 , Final2 , Final3 , Final4]
      });
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }  
  };

  async function SaveJobId(ID) {
    try {
      await APICALL({
        apicall: 'PD_update',
        code: sessionStorage.getItem('process'),
        U_emp: sessionStorage.getItem('Grupo'), 
        JobId: ID,
      });
    } catch (error) {
      console.error('Error during fetch:', error);
      return { token: null };
    }  
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await APICALL({
        apicall :'PD_fetch' ,
        code :sessionStorage.getItem("process"),
        U_emp: sessionStorage.getItem('Grupo')
      });
      localStorage.removeItem('fetched');
      localStorage.setItem('fetched', data);
      setFetchedData(data)
      if (data.GPToferta){
        setOferta(data.GPToferta);
      } else {
        GenerateOferta();
      }
      if (data.GPTpreguntas){
        
        setPregunta1(data.GPTpreguntas[0]);
        setPregunta2(data.GPTpreguntas[1]);
        setPregunta3(data.GPTpreguntas[2]);
        setPregunta4(data.GPTpreguntas[3]);
      } else {
        GeneratePreguntas();
      }
      
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  // Function to generate and save PDF
  const exportToPDFoferta = () => {
    const doc = new jsPDF();

    doc.text(oferta, 10, 10);
    doc.save('oferta.pdf');
  };

  const dataItems = [
    { label: 'CARGO',                   value: fetchedData.Charge_name 	? fetchedData.Charge_name.toUpperCase() : '' }, 
    { label: 'EMPRESA',                 value: fetchedData.E_name 			? fetchedData.E_name.toUpperCase() : '' }, 
    { label: 'INTERLOCUTOR',            value: fetchedData.I_name 			? fetchedData.I_name.toUpperCase() : '' },
		{ label: 'PARTNER ENCARGADO',       value: fetchedData.R_name 		  ? fetchedData.R_name.toUpperCase() : '' }
  ];

  return (
    // main
    <div className="BackGround">
      <div className="typing-effect-container">
				<h2 className="typing-effect" > Tambien he creado para ti este AVISO DE EMPLEO. Puedes editarlo o pedirme que lo genere nuevamente</h2>
			</div>
      
      <div className='MainBody'>

        <div className='boxfit'>
          <h3 className = 'boxtitle'>Aviso Generado</h3>
          <textarea className='notas'
            value={oferta}
            onChange={handleChange}
            spellCheck 
          />
        </div>
        &nbsp;
        <div className='boxfit'>
          <h3 className = 'boxtitle'>Preguntas de Aviso</h3>
          <div className='notas'>
            <textarea className={Final1 ? 'pSaved' : 'preguntas'}
              value={pregunta1}
              placeholder="espere mientras se genera el contenido..."
              onChange={(event) => { let newValue = event.target.value.replace(/"/g, '') ; setPregunta1(newValue) }}/>
            <button onClick={() => setFinal1(pregunta1)}> Seleccionar Pregunta </button>

            <textarea className={Final2 ? 'pSaved' : 'preguntas'}
              value={pregunta2}
              placeholder="espere mientras se genera el contenido..."
              onChange={(event) => { let newValue = event.target.value.replace(/"/g, '') ; setPregunta2(newValue) }}/>
            <button onClick={() => setFinal2(pregunta2)}> Seleccionar Pregunta </button>

            <textarea className={Final3 ? 'pSaved' : 'preguntas'}
              value={pregunta3}
              placeholder="espere mientras se genera el contenido..."
              onChange={(event) => { let newValue = event.target.value.replace(/"/g, '') ; setPregunta3(newValue) }}/>
            <button onClick={() => setFinal3(pregunta3)}> Seleccionar Pregunta </button>

            <textarea className={Final4 ? 'pSaved' : 'preguntas'}
              value={pregunta4}
              placeholder="espere mientras se genera el contenido..."
              onChange={(event) => { let newValue = event.target.value.replace(/"/g, '') ; setPregunta4(newValue) }}/>
          <button onClick={() => setFinal4(pregunta4)}> Seleccionar Pregunta </button>

          </div>
        </div>
        &nbsp; 
        <div className="data-boxes">
          {dataItems.map((item, index) => (
            <div key={index} className="data-box">
              <strong>{item.label}:</strong> {item.value}
            </div>
          ))}
          <button onClick={GeneratePreguntas}>Regenerar Preguntas Sugeridas</button>
          <button onClick={() => {setregen(!regen); setPublicar(false)}}> Regenerar Aviso Laboral </button>

          <button className = {regen ? "PublicButon" : "hidden"}
                  onClick={() => GenerateOferta('')}> Regenerar nuevo Aviso Laboral</button>
          <button className = {regen ? "PublicButon" : "hidden"}
                  onClick={() => GenerateOferta(notas1)}>Regenerar como Consultora</button>
          <button className = {regen ? "PublicButon" : "hidden"}
                  onClick={() => GenerateOferta(notas2)}>Regenerar como Empresa Contratante</button>
          <button className = {regen ? "PublicButon" : "hidden"}
                  onClick={() => GenerateOferta(notas3)}>Regenerar como Empresa Confidencial</button>

          <button onClick={SaveOferta}> Guardar Aviso de empleo </button>
          <button onClick={SavePregunta}> Guardar Preguntas Generadas </button>
          <button onClick={fetchData}>Volver a la ultima version guardada</button>
          <button onClick={exportToPDFoferta}>Exportar Aviso de Empleo a PDF</button>
          <button onClick={() => {setPublicar(!Publicar); setregen(false)}}>Publicar el Anuncio</button>

          <button className = {Publicar ? "PublicButon" : "hidden"}
                  onClick={exportToTrabajando}>Publicar en Trabajando.com</button>
          <button className = {Publicar ? "PublicButoff" : "hidden"}
                  >Publicar en LinkedIn</button>
          <button className = {Publicar ? "PublicButoff" : "hidden"}
                  >Publicar en Laborum</button>
          <button className = {Publicar ? "PublicButoff" : "hidden"}
                  >Publicar en Chile Trabajo</button>
          <div>
            {wait === false ? (
              <div>
              </div>
            ) 
            : (
              <div className='box'>
                <strong>Espere Mientras Reneé trabaja</strong>
                <div className="loader-container">
                  <div className="pulsing-dot">
                  <div></div>
                  <div></div>
                  <div></div>
                  </div>
                </div>
              </div>
            )}
          </div>  
        </div>
      </div>
      <div className='bottom'>
        <button onClick={goto05}>volver</button>
          <div></div>
          <div></div>
          <div></div>
        <button onClick={goto07}>Continuar a candidatos</button>
      </div>
    </div>
  );
}
