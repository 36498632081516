
//=============================================================================
// Función  LOGIN Reneé
//=============================================================================

export default async function LOGCALL(data) {

	// return fetch('http://localhost:8090/ReneeLog', {
	return fetch('https://api.renee.cl/ReneeLog', {
		method: 'POST',
		headers: { 	'Content-Type': 'application/json'},
		body: JSON.stringify(data)
	})
	.then(response => response.json())
	.catch(error => {
		console.error('Error during fetch:', error);
		return { response: '' };
	});
}