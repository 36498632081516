
//=============================================================================
// Pantalla Datos del Proceso
//=============================================================================

import './css/App.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import APICALL from './APICALL';

export default function Eprocess({ setreply }) {

  const navigate = useNavigate();
  const [I_name, setI_name]   = useState('');
  const [R_name, setR_name]   = useState('');
  const [I_name2, setI_name2] = useState('');
  const [R_name2, setR_name2] = useState('');

  const [selectedLanguage, setSelectedLanguage]             = useState('');
  const [selectedComp, setSelectedComp]                     = useState('');
  const [selectedKnowledgeLevel, setSelectedKnowledgeLevel] = useState('');

  const [Charge_name, setCharge_name]                 = useState('');
  const [Charge_type, setCharge_type]                 = useState('');
  const [Available_slots, setAvailable_slots]         = useState('');
  const [E_name, setE_name]                           = useState('');
  const [Confidential, setConfidential]               = useState('');
  const [Work_mode, setWork_mode]                     = useState('');
  const [Work_time, setWork_time]                     = useState('');
  const [Work_country, setWork_country]               = useState('');
  const [Work_region, setWork_region]                 = useState('');
  const [Work_comuna, setWork_comuna]                 = useState('');
  const [Work_place, setWork_place]                   = useState('');
  const [E_activity, setE_activity]                   = useState('');
  const [Work_Area, setWork_Area]                     = useState('');
  const [Work_class, setWork_class]                   = useState('');
  const [Currency, setCurrency]                       = useState('');
  const [Work_pay_min, setWork_pay_min]               = useState('');
  const [Work_pay_max, setWork_pay_max]               = useState('');
  const [ContractTime, setContractTime]               = useState('');

  const [Work_Experience, setWork_Experience]         = useState('');
  const [Work_Detail, setWork_Detail]                 = useState('');
  const [Study_min, setStudy_min]                     = useState('');
  const [Study_state, setStudy_state]                 = useState(''); 
  const [Requirements, setRequirements]               = useState(['']);

  const [Timewith, setTimewith]                       = useState('');
  const [Timedetail, setTimedetail]                   = useState('');

  const [fetch, setfetch]                             = useState('');

  const code                = sessionStorage.getItem("process")
  const [P_name, setP_name] = useState('');

  const handleSubmit = async (e) => {

    try {
      await APICALL({
        apicall: 'PD_create',
        code,
        P_name,
        U_emp: sessionStorage.getItem('Grupo'),
  
        Charge_name,        // * Nombre del cargo
        Charge_type,        // * Categoría del cargo
        Available_slots,    // * Vacantes disponibles
        E_name,             // * Nombre de la empresa
        Confidential,       // * Confidencialidad
        Work_mode,          // * Modalidad de trabajo
        Work_time,          // * Jornada de trabajo
        Work_country,       // * País de trabajo
        Work_region,        //   Region de trabajo
        Work_comuna,        //   Comuna de trabajo
        Work_place,         // * Lugar de trabajo
        E_activity,         // * Actividad de la empresa
        Work_Area,          // * Area de trabajo
        Work_class,         // * Jerarquía laboral
        Currency,           // * Moneda ed pago
        Work_pay_min,       // * Rango de pago
        Work_pay_max,       // * Rango de pago
        ContractTime,       // * Duración del contrato
  
        Work_Experience,    // * Experiencia previa
        Work_Detail,        //   Detalle de experiencia
        Study_min,          // * Estudios mínimos
        Study_state,        // * Condición de estudios
        Requirements,       //   Requisitos Lenguaje
        
        R_name,             // * Nombre del partner 1
        R_name2,            //   Nombre del partner 2
        I_name,             // * Interlocutor asociado  
        I_name2,            //   Interlocutor asociado 2
        Timewith,           // * Vigencia
        Timedetail,          //   Vigencia Detalle
  
        notas       : fetch.notas,
        GPTreply    : fetch.GPTreply,
        GPToferta   : fetch.GPToferta,
        GPTpreguntas: fetch.GPTpreguntas,
        CVData      : fetch.CVData,
        SelectList  : fetch.SelectList,
        JobId       : fetch.JobId

      });
      navigate('/GenPerfil'); 
      
    } catch (error) {
      console.error('API call failed', error);
    }
  }

  const goto03 = () => { navigate('/Notas') };

  document.addEventListener('DOMContentLoaded', () => {

    const data = require("./docs/Trabajando.json")
    const regionSelect = document.getElementById('region');
    const comunaSelect = document.getElementById('comuna');
    regionSelect.innerHTML = '';
    data.regiones.forEach(regionObj => {
      const option = document.createElement('option');
      option.value = regionObj.region;
      option.textContent = regionObj.region;
      regionSelect.appendChild(option);
    });
    regionSelect.addEventListener('change', (event) => {
      const selectedRegion = event.target.value;
      const regionObj = data.regiones.find(region => region.region === selectedRegion);
      comunaSelect.innerHTML = '';

      if (regionObj) {
        regionObj.comunas.forEach(comuna => {
          const option = document.createElement('option');
          option.value = comuna;
          option.textContent = comuna;
          comunaSelect.appendChild(option);
        });
      }
    });
    regionSelect.dispatchEvent(new Event('change'));
  });

  useEffect(() => {
    const fetchData = async () => {
      const data = await APICALL({
        apicall: 'PD_fetch',
        code: sessionStorage.getItem("process"),
        U_emp: sessionStorage.getItem('Grupo')
      });
      setfetch(data);
      setP_name(data.P_name);
      setE_name(data.E_name);
      setI_name(data.I_name);
      setR_name(data.R_name);
      setI_name2(data.I_name2);
      setR_name2(data.R_name2);
      setCharge_name(data.Charge_name);
      setCharge_type(data.Charge_type);
      setAvailable_slots(data.Available_slots);
      setWork_mode(data.Work_mode);
      setWork_time(data.Work_time);
      setWork_country(data.Work_country);
      setWork_region(data.Work_region);
      setWork_comuna(data.Work_comuna);
      setWork_place(data.Work_place);
      setE_activity(data.E_activity);
      setWork_class(data.Work_class);
      setWork_pay_min(data.Work_pay_min);
      setWork_pay_max(data.Work_pay_max);
      setWork_Experience(data.Work_Experience);
      setWork_Detail(data.Work_Detail);
      setStudy_min(data.Study_min);
      setStudy_state(data.Study_state);
      setTimewith(data.Timewith);
      setTimedetail(data.Timedetail);
      setContractTime(data.ContractTime);
      setWork_Area(data.Work_Area);
      setCurrency(data.Currency);
      setConfidential(data.Confidential);
      setRequirements(['']);
      setRequirements(data.Requirements);

      const doc = require("./docs/Trabajando.json");

      const CargosSelect = document.getElementById('cargos');
      CargosSelect.innerHTML = '';
      doc.Cargos.forEach(CarObj => {
        const option = document.createElement('option');
        option.value = CarObj;
        option.textContent = CarObj;
        CargosSelect.appendChild(option);
      });

      const ModSelect = document.getElementById('Modalidad');
      ModSelect.innerHTML = '';
      doc.Modalidad.forEach(Obj => {
        const option = document.createElement('option');
        option.value = Obj;
        option.textContent = Obj;
        ModSelect.appendChild(option);
      });

      const JornadaSelect = document.getElementById('Jornada');
      JornadaSelect.innerHTML = '';
      doc.Jornada.forEach(Obj => {
        const option = document.createElement('option');
        option.value = Obj;
        option.textContent = Obj;
        JornadaSelect.appendChild(option);
      });

      const PaisSelect = document.getElementById('Pais');
      PaisSelect.innerHTML = '';
      doc.Paises.forEach(PaisObj => {
        const option = document.createElement('option');
        option.value = PaisObj;
        option.textContent = PaisObj;
        PaisSelect.appendChild(option);
      });

      const regionSelect = document.getElementById('region');
      const comunaSelect = document.getElementById('comuna');
      regionSelect.innerHTML = '';
      doc.regiones.forEach(regionObj => {
        const region = regionObj.region[0];
        const option = document.createElement('option');
        option.value = region.nombre;
        option.textContent = region.nombre;
        regionSelect.appendChild(option);
      });
      regionSelect.value = data.Work_region;
      const handleRegionChange = (event) => {
        const selectedRegionName = event.target.value;
        const regionObj = doc.regiones.find(region => region.region[0].nombre === selectedRegionName);
        comunaSelect.innerHTML = '';
        if (regionObj) {
          regionObj.comunas.forEach(comuna => {
            const option = document.createElement('option');
            option.value = comuna.nombre;  // Set id as the value
            option.textContent = comuna.nombre;  // Set nombre as the text
            comunaSelect.appendChild(option);
          });
        }
        comunaSelect.value = data.Work_comuna;
      };
      
      const ActividadSelect = document.getElementById('ActividadEmp');
      ActividadSelect.innerHTML = '';
      doc.Actividadempresa.forEach(ActObj => {
        const option = document.createElement('option');
        option.value = ActObj;
        option.textContent = ActObj;
        ActividadSelect.appendChild(option);
      });

      const AreaSelect = document.getElementById('Area');
      AreaSelect.innerHTML = '';
      doc.Area.forEach(Obj => {
        const option = document.createElement('option');
        option.value = Obj;
        option.textContent = Obj;
        AreaSelect.appendChild(option);
      });

      const VigenciaSelect = document.getElementById('Vigencia');
      VigenciaSelect.innerHTML = '';
      doc.Vigencia.forEach(Obj => {
        const option = document.createElement('option');
        option.value = Obj.id;
        option.textContent = Obj.Nombre;
        VigenciaSelect.appendChild(option);
      });

      const ExpSelect = document.getElementById('Experiencia');
      ExpSelect.innerHTML = '';
      doc.Experiencia.forEach(Obj => {
        const option = document.createElement('option');
        option.value = Obj.id;
        option.textContent = Obj.Nombre;
        ExpSelect.appendChild(option);
      });

      const MinEstSelect = document.getElementById('MinEst');
      MinEstSelect.innerHTML = '';
      doc.MinEstudio.forEach(Obj => {
        const option = document.createElement('option');
        option.value = Obj;
        option.textContent = Obj;
        MinEstSelect.appendChild(option);
      });

      const SitAcaSelect = document.getElementById('SitAca');
      SitAcaSelect.innerHTML = '';
      doc.SituacionAcademica.forEach(Obj => {
        const option = document.createElement('option');
        option.value = Obj;
        option.textContent = Obj;
        SitAcaSelect.appendChild(option);
      });

      const IdiomSelect = document.getElementById('Idioma');
      IdiomSelect.innerHTML = '';
      doc.Idiomas.forEach(Obj => {
        const option = document.createElement('option');
        option.value = Obj;
        option.textContent = Obj;
        IdiomSelect.appendChild(option);
      });
  
      regionSelect.addEventListener('change', handleRegionChange);
      regionSelect.dispatchEvent(new Event('change'));
    };
  
    fetchData();
  }, []);

  const handleLanguageChange = (e) => {
    setSelectedLanguage(e.target.value);
    setSelectedKnowledgeLevel(''); 
  };

  const handleCompChange = (e) => {
    setSelectedComp(e.target.value);
    setSelectedKnowledgeLevel(''); 
  };

  const handleKnowledgeChange = (e) => {
    if (selectedLanguage) {
      setRequirements((prevRequirements) => [
        ...(Array.isArray(prevRequirements) ? prevRequirements : []),
        [selectedLanguage + ' - ' + e.target.value],
      ]);
      setSelectedLanguage('');
    } else if (selectedComp) {
      setRequirements((prevRequirements) => [
        ...(Array.isArray(prevRequirements) ? prevRequirements : []),
        [selectedComp + ' - ' + e.target.value],
      ]);
      setSelectedComp('');
    }
  };

  const removeSelection = (index) => {
    setRequirements(Requirements.filter((_, i) => i !== index));
  };
  

  return (
    // main
    <div className='BackGround'>
      <div className="typing-effect-container">
        <h2 className="typing-effect" >Vamos a llenar los datos del proceso que creaste! o Modifica los formularios de la manera que necesites</h2>
			</div>

      <div className='MainBody'>

        <form id='myform' className="form-container" onSubmit={handleSubmit}>

        <h2 className='form-title'>Descripción General</h2>

          <label><strong>NOMBRE DEL CARGO *</strong>
            <input  type="text" 
                    value= {Charge_name}
                    className={Charge_name ? 'highlighted2' : ''}
                    onChange={e => setCharge_name(e.target.value)} 
                    required />
          </label>

          <label><strong>TIPO DE CARGO  *</strong>
          <select id="cargos"
            value={Charge_type ? Charge_type : ''}
            placeholder={'ingrese un valor'}
            className={Charge_type ? 'highlighted2' : ''}
            onChange={e => setCharge_type(e.target.value)}>
          </select>
          </label>

          <label><strong>CANTIDAD DE VACANTES *</strong>
          <input  type="number" 
                  value= {Available_slots ? Available_slots : 'ingrese un valor'}
                  placeholder={'ingrese un valor'}
                  className={Available_slots ? 'highlighted2' : ''}
                  onChange={e => setAvailable_slots(e.target.value)} 
                  required/>
          </label>

          <label><strong>NOMBRE DE LA EMPRESA</strong>
          <input  value= {E_name}
                  readOnly
                  className={E_name ? 'highlighted2' : ''}/>
          </label>

          <label><strong>CONFIDENCIALIDAD *</strong>
          <select className={Confidential ? 'highlighted2' : ''} 
                  onChange={e => setConfidential(e.target.value)} 
                  id='Hidden'
                  required>
            <option> {Confidential ? Confidential : 'Elija un valor'} </option>
            <option value="Publico"     > Publico                </option>
            <option value="Confidencial"> Confidencial           </option>
          </select>
          </label>

          <label><strong>MODALIDAD DE TRABAJO *</strong>
          <select id="Modalidad"
            value={Work_mode ? Work_mode : ''}
            className={Work_mode ? 'highlighted2' : ''}
            onChange={e => setWork_mode(e.target.value)}>
          </select>
          </label>

          <label><strong>JORNADA DE TRABAJO *</strong>
          <select id="Jornada"
            value={Work_time ? Work_time : ''}
            className={Work_time ? 'highlighted2' : ''}
            onChange={e => setWork_time(e.target.value)}>
          </select>
          </label>

          <label><strong>PAIS DE TRABAJO *</strong>
          <select id="Pais"
                    value={Work_country ? Work_country : ''}
                    className={Work_country ? 'highlighted2' : ''}
                    onChange={e => setWork_country(e.target.value)}>
          </select>
          </label>

          <div className={Work_country && Work_country.trim().toLowerCase() === 'chile' ? '' : 'hidden'}>
            <label><strong>REGION DE TRABAJO </strong>
            <select id="region"
                    value={Work_region ? Work_region : ''}
                    className={Work_region ? 'highlighted2' : ''}
                    onChange={e => setWork_region(e.target.value)}>
            </select>
            </label>
          </div>

          <div className={Work_region ? '' : 'hidden'}>
            <label><strong>COMUNA DE TRABAJO </strong>
            <select id="comuna" 
                    value={Work_comuna ? Work_comuna : ''}
                    className={Work_comuna ? 'highlighted2' : ''}
                    onChange={e => setWork_comuna(e.target.value)}>
            </select>
            </label>
          </div>

          <label><strong>LUGAR DE TRABAJO *</strong>
          <input  type="text" 
                  value= {Work_place ? Work_place : ''}
                  placeholder={'ingrese un valor'}
                  className={Work_place ? 'highlighted2' : ''}
                  onChange={e => setWork_place(e.target.value)} 
                  required/>
          </label>

          <label><strong>ACTIVIDAD DE LA EMPRESA *</strong>
          <select id="ActividadEmp" 
            value={E_activity ? E_activity : ''}
            className={E_activity ? 'highlighted2' : ''}
            onChange={e => setE_activity(e.target.value)}>
          </select>
          </label>

          <label><strong>AREA DE CARGO  *</strong>
          <select id="Area"
            value={Work_Area ? Work_Area : ''}
            className={Work_Area ? 'highlighted2' : ''}
            onChange={e => setWork_Area(e.target.value)}>
          </select>
          </label>

          <label><strong>JERARQUIA LABORAL *</strong>
          <select className={Work_class ? 'highlighted2' : ''} 
                  onChange={e => setWork_class(e.target.value)} 
                  required>
            <option> {Work_class ? Work_class : 'Elija un valor'} </option>
            <option value=" Gerencia "    > Gerencia/ Direccion                 </option>
            <option value=" Supervicion " > Supervicion/ Jefatura/ Sub-Gerencia </option>
            <option value=" Responsable " > Responsable                         </option>
            <option value=" Senior "      > Posicion Senior                     </option>
            <option value=" Junior "      > Posicion Junior                     </option>
            <option value=" Empleado "    > Empleado                            </option>
            <option value=" Operador "    > Operador                            </option>
            <option value=" Asesor "      > Asesor                              </option>
            <option value=" Practica "    > Alumno en Practica                  </option>
          </select>
          </label>

          <label><strong>MONEDA DE PAGO *</strong>
          <select className={Currency ? 'highlighted2' : ''} 
                  onChange={e => setCurrency(e.target.value)} 
                  id='Currency'
                  required>
            <option>{Currency ? Currency : 'Elija un valor'}</option>
            <option value="Dolar"         > Dolar           </option>
            <option value="Euro"          > Euro            </option>
            <option value="Pesos-Chilenos"> Pesos-Chilenos  </option>
          </select>
          </label>

          <label><strong>RENTA DE LA POSICION *</strong>
          <div className={Currency}>
            <input  type="number" 
                    min={0} 
                    value={Work_pay_max ? Work_pay_max : ''}
                    placeholder={'ingrese un valor'}
                    className={Work_pay_max ? 'highlighted2' : ''}
                    onChange={e => setWork_pay_max(e.target.value)} 
                    required/>
          </div>
          <div className={Currency}>
            <input  type="number" 
                    min={0} 
                    value={Work_pay_min ? Work_pay_min : ''}
                    placeholder={'ingrese un valor'}
                    className={Work_pay_min ? 'highlighted2' : ''}
                    onChange={e => setWork_pay_min(e.target.value)} 
                    required/>
          </div>
          </label>

          <label><strong>DURACION DEL CONTRATO *</strong>
          <input  type="text" 
                  value= {ContractTime ? ContractTime : ''}
                  placeholder={'ingrese un valor'}
                  className={ContractTime ? 'highlighted2' : ''}
                  onChange={e => setContractTime(e.target.value)} 
                  id='Contract_Time'
                  required/>
          </label>

        <h2 className='form-title'>Requisitos</h2>

          <label><strong>EXPERIENCIA LABORAL *</strong>
          <select className={Work_Experience ? 'highlighted2' : ''} 
                  onChange={e => setWork_Experience(e.target.value)} 
                  required>
            <option> {Work_Experience ? Work_Experience : 'Elija un valor'} </option>
            <option value="Sin preferencia"   > Sin preferencia   </option>
            <option value="Sin experiencia"   > Sin experiencia   </option>
            <option value="Igual a"           > Igual a           </option>
            <option value="Desde"             > Desde             </option>
            <option value="Hasta"             > Hasta             </option>
          </select>
          </label>

          <div className={Work_Experience === 'Desde' || Work_Experience === 'Igual a' || Work_Experience === 'Hasta' ? '' : 'hidden'}>
            <label><strong>DETALLE EXPERIENCIA LABORAL </strong>
            <select id="Experiencia"
              value = {Work_Detail ? Work_Detail : ''}
              className={Work_Detail ? 'highlighted2' : ''}
              onChange={e => setWork_Detail(e.target.value)}>
            </select>
            </label>
          </div>

          <label><strong>ESTUDIOS MINIMOS *</strong>
          <select id="MinEst"
            value = {Study_min ? Study_min : ''}
            className={Study_min ? 'highlighted2' : ''}
            onChange={e => setStudy_min(e.target.value)}>
          </select>
          </label>

          <label><strong>SITUACION ACADEMICA *</strong>
          <select id="SitAca"
            value = {Study_state ? Study_state : ''}
            className={Study_state ? 'highlighted2' : ''}
            onChange={e => setStudy_state(e.target.value)}>
          </select>
          </label>

          <label><strong>COMPETENCIAS / HABILIDADES *</strong>
          <input  id="Competencia"
                  placeholder='Elija una Competencia'
                  type="text" 
                  onChange={handleCompChange}/>
          </label>

          {selectedComp && (
            <label>
              <strong>{selectedComp} - NIVEL * </strong>
              <select
                value={selectedKnowledgeLevel}
                onChange={handleKnowledgeChange}
              >
                <option value="0"     > Seleccione una Categoría </option>
                <option value="Bajo"  > Bajo                     </option>
                <option value="Medio" > Medio                    </option>
                <option value="Alto"  > Alto                     </option>
              </select>
            </label>
          )}

          <label><strong>IDIOMAS *</strong>
          <select id="Idioma"
            value={selectedLanguage ? selectedLanguage : 'Elija un valor'}
            onChange={handleLanguageChange}>
          </select>
          </label>

          {selectedLanguage && (
            <label>
              <strong>{selectedLanguage} - NIVEL * </strong>
              <select
                value={selectedKnowledgeLevel}
                onChange={handleKnowledgeChange}
              >
                <option value="0"     > Seleccione una Categoría </option>
                <option value="Bajo"  > Bajo                     </option>
                <option value="Medio" > Medio                    </option>
                <option value="Alto"  > Alto                     </option>
                <option value="Nativo"> Nativo                   </option>
              </select>
            </label>
          )}

          <div className='form-lista'>
            <ul>
            {(Requirements || []).map(([language], index) => (
              <button key={index} onClick={() => removeSelection(index)}>
                {language}
              </button>
            ))}
            </ul>
          </div>

          {/* <div className={Timewith === '1' ? '' : 'hidden'}>
            <label><strong>FECHA DE VIGENCIA</strong>
            <input  type="date"
                    placeholder= {Timedetail ? Timedetail : 'Ingrese un valor'}
                    className={Timedetail ? 'highlighted2' : ''}
                    onChange={e => setTimedetail(e.target.value)} 
                    required/>
            </label>
          </div> */}

        <h2 className='form-title'>DATOS ADICIONALES</h2>

          <label><strong>NOMBRE DEL INTERLOCUTOR/A </strong>
          <input  value= {I_name}
                  readOnly
                  className={I_name ? 'highlighted2' : ''}/>
          </label>

          <label><strong>INTERLOCUTORES ADICIONALES</strong>
          <input  type="text" 
                  placeholder= {I_name2 ? I_name2 : 'Ingrese un valor'}
                  className={I_name2 ? 'highlighted2' : ''}
                  onChange={e => setI_name2(e.target.value)}/>
          </label>

          <label><strong>HEAD HUNTER ENCARGADO/A</strong>
          <input  value= {R_name}
                  readOnly
                  className={R_name ? 'highlighted2' : ''}/>
          </label>

          <label><strong>HEAD HUNTER DELEGADO</strong>
          <input  type="text" 
                  placeholder= {R_name2 ? R_name2 : 'Ingrese un valor'}
                  className={R_name2 ? 'highlighted2' : ''}
                  onChange={e => setR_name2(e.target.value)}/>
          </label>

          <label><strong>VIGENCIA DE LA PUBLICACION *</strong>
          <select id="Vigencia"
            value = {Timewith ? Timewith : ''}
            className={Timewith ? 'highlighted2' : ''}
            onChange={e => setTimewith(e.target.value)}>
          </select>
          </label>
        </form>

      </div>
      <div className='bottom'>
        <button type="button" onClick={goto03}>volver</button>
        <div></div>
        <div></div>
        <div></div>
        <button onClick={() => document.getElementById('myform').requestSubmit()}>Guardar y Generar Perfil</button>
      </div>
    </div>
  );

}
