
//=============================================================================
// Pantalla Login
//=============================================================================

import './css/App.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LOGCALL from './LOGCALL';

export default function Login({ setToken }) {
  
  const navigate = useNavigate();

  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
	const [U_name , setU_name]    = useState('');
  const [U_city , setU_city]    = useState('');
  const [U_mail , setU_mail]    = useState('');
  const [U_phone, setU_phone]   = useState('');
  const [U_emp  , setU_emp]     = useState('');

  const [Popup1, setPopup1]     = useState(false);
  const [Popup2, setPopup2]     = useState(false);
  const [error, setError]       = useState(''); 
  let count                     = 0;
  

  const HandleLoggin = async e => {
    e.preventDefault();
    setError('');

    const result = await LOGCALL({
      apicall: '00',
      username,
      password
    });

    if (result.token) {
      console.log(result);
      sessionStorage.setItem('Grupo', result.empresa);
      sessionStorage.setItem('token', result.token);
      sessionStorage.setItem('mail', username);
      sessionStorage.setItem('rol', result.rol);
      window.location.reload();
    } else {
      setError(result.error || ' '); 
    }
  }

  const Handleout = async e => {
    setPopup1(false);
    setPopup2(false);
  }

  async function MailContacto(){
    await LOGCALL({
      apicall: '02',
      U_name : U_name,
      U_city : U_city,
      U_mail : U_mail,
      U_phone : U_phone,
      U_emp : U_emp
    });
    alert('Gracias por contactarnos, pronto le responderemos !');
  }

  async function MailRecuperar(){
    const result = await LOGCALL({
      apicall: '03',
      U_mail : U_mail
    });
    if (result.error){
      alert('Mail no registrado');
    } else {
      alert('Pronto recibiras una contraseña temporal a tu dirección de correo');
    }
  }

  const GotoMS = () => {
    if (count === 5){
      navigate('/MS'); 
    } else {
      count = count+1;
    }
	};

  return(
    <main className="loggin">
      <div
            className={Popup1 ? 'popupBG' : 'hidden'}>
        <div className='popup'> 
          <h2>Nos pondremos en contacto contigo para que te puedas unir a Reneé </h2>
          <div className='out' onClick={Handleout}>X</div>
          <div>
            <form onSubmit={MailContacto} className='popform'>
            <label><strong>Nombre</strong>
            <input  type='text'
                    id="Nombre"
                    required
                    placeholder= {'Nombre'}
                    onChange={e => setU_name(e.target.value)}/>
            </label>

            <label><strong>Teléfono</strong>
            <input  type='number'
                    id="Telefono"
                    required
                    placeholder= {'Telefono'}
                    onChange={e => setU_phone(e.target.value)}/>
            </label>

            <label><strong>Ciudad</strong>
            <input  type='text'
                    id="Ciudad"
                    required
                    placeholder= {'Ciudad'}
                    onChange={e => setU_city(e.target.value)}/>
            </label>

            <label><strong>Empresa</strong>
            <input  type='text'
                    id="Empresa"
                    required
                    placeholder= {'Empresa'}
                    onChange={e => setU_emp(e.target.value)}/>
            </label>

            <label><strong>Correo</strong>
            <input  type='email'
                    id="Correo"
                    required
                    placeholder= {'Correo'}
                    onChange={e => setU_mail(e.target.value)}/>
            </label>

            <button style={{width:'50%',marginBottom:'0px'}} type='submit'> enviar tus datos </button>
            
            </form>
          </div>
        </div>
      </div>
      <div
            className={Popup2 ? 'popupBG' : 'hidden'}>
        <div className='popup'>
          <div className='out' onClick={Handleout}>X</div> 
          <h2> Has olvidado tu contraseña?  </h2>
          <h3> Ingresa el mail en el cual quieres recibir tu contraseña temporal </h3>
          <div>
            <form  onSubmit={MailRecuperar} className='popform'>

            <label><strong>Correo</strong>
            <input  type='email'
                    id="Mail"
                    required
                    placeholder= {'Correo'}
                    onChange={e => setU_mail(e.target.value)}/>
            </label>
            <button type='submit' style={{width:'50%',marginBottom:'0px'}}> enviar tus datos </button>
            
            </form>
          </div>
        </div>
      </div>
      <img 
        src="/favicon.png" 
        alt="favicon" 
        className="logo" 
        onClick={GotoMS}
      />
      <div className='MainBody'>
        <div className='boxlog'>
          <h2>Inicie una nueva sesión</h2>
          <form onSubmit={HandleLoggin}>
        
            <input type="text" placeholder='Correo Electronico'
            onChange={e => setUserName(e.target.value)} />
        
            <input type="password" placeholder='Contraseña'
            onChange={e => setPassword(e.target.value)} />
        
            <button type="submit">Iniciar sesión</button>
              
            {error && <div className="error">{error}</div>}
          </form>
          <div className='box1'>
          <h4 onClick={ () => setPopup1(true) }> Quiero Unirme a Reneé </h4>
          <h4 onClick={ () => setPopup2(true) }> Olvide mi contraseña </h4>
          </div>
        </div>
      </div>
    </main>
  );
}

